import React, { useEffect, useRef, useState } from "react";
import { Alert, Col, Form, Spinner, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { useParams } from "react-router";
import SplitPane from "react-split-pane";
import { FaSquarePlus } from "react-icons/fa6";
import Pagination from "../../../ETMF/Pagination/Pagination";
import { studyOnboardingService } from "../../../../Services/studyServices/studyOnboarding";

const ProtocolTrialSummeryMetadata = ({ tableRef, onMouseDown, viewTypeData }) => {
  const ref = useRef(null);
  const { studyId } = useParams();
  const [name, setName] = useState("");
  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");
  const [idData, setIdData] = useState("");
  const [type, setType] = useState("");
  const [protocolList, setProtocolList] = useState([]);

  const [responseMessage, setResponseMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [statusCode, setStatusCode] = useState("");
  setTimeout(() => {
    setResponseMessage("");
    setStatusCode("");
  }, 10000);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = protocolList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(protocolList?.length / itemsPerPage);

  const getProtocolMetadataData = async () => {
    let data = await studyOnboardingService.getProtocolMetadataData(studyId);
    setProtocolList(data?.data);
  };

  const addProtocolMetadata = async () => {
    setLoader(true);
    let type = "post";
    let userData = { studyId, name, label, type, value };
    let data = await studyOnboardingService.addProtocolMetadata(userData);
    if (data?.statusCode === 200) {
      setName("");
      setLabel("");
      setValue("");
      getProtocolMetadataData();
      setLoader(false);
    } else {
      setLoader(false);
    }
    setResponseMessage(data?.customMessage);
    setStatusCode(data?.statusCode);
  };

  const updateProtocolMetadataData = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update this Protocol?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        let userData = { id: idData, name, label, value };
        let data = await studyOnboardingService.updateProtocolMetadataData(
          userData
        );
        if (data?.statusCode === 200) {
          getProtocolMetadataData();
          setLoader(false);
          setName("");
          setLabel("");
          setValue("");
          setIdData("");
        } else {
          setLoader(false);
        }
        setResponseMessage(data?.customMessage);
        setStatusCode(data?.statusCode);
      }
    });
  };

  const deleteProtocolMetadataData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Protocol?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await studyOnboardingService.deleteProtocolMetadataData(id);
        if (data?.statusCode === 200) {
          getProtocolMetadataData();
          if (idData === id) {
            setIdData("");
            setName("");
            setLabel("");
            setValue("");
          }
        }
      }
    });
  };

  const updateProtocol = (id, name, label, value, type) => {
    setIdData(id);
    setName(name);
    setLabel(label);
    setValue(value);
    setType(type);
  };

  const addNewProtocol = () => {
    setIdData("");
    setType("");
    setName("");
    setLabel("");
    setValue("");
  };

  useEffect(() => {
    getProtocolMetadataData();
  }, []);

  return (
    <div>
      <SplitPane
        split="vertical"
        className="position-relative"
        minSize={viewTypeData === "ctms" ? 20 : 0}
        defaultSize={viewTypeData === "ctms" ? "20%" : "0%"}
        style={{ height: "calc(100vh - 158px)" }}
      >
        <div className="p-2">
          <div className="hstack justify-content-between">
            <Form.Label className="m-0">
              <b>
                Parameter Name <span className="text-danger">*</span>
              </b>
            </Form.Label>
            {idData && (
              <button
                className="mb-1 bg-dark-subtle   bg-opacity-10 border"
                style={{ fontSize: 12 }}
                onClick={() => addNewProtocol()}
                title="Add New Protocol"
              >
                <FaSquarePlus className="me-2" style={{ marginTop: -2 }} />
                Add New Protocol
              </button>
            )}
          </div>
          <Form.Control
             
            required
            type="text"
            disabled={type === "pre"}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Parameter Name"
          />
          <Form.Label className="mt-2 mb-1">
            <b>
              Parameter Label <span className="text-danger">*</span>
            </b>
          </Form.Label>
          <Form.Control
             
            required
            type="text"
            value={label}
            disabled={type === "pre"}
            onChange={(e) => setLabel(e.target.value)}
            placeholder="Enter Parameter Label"
          />
          <Form.Label className="mt-2 mb-1">
            <b>
              Parameter Value <span className="text-danger">*</span>
            </b>
          </Form.Label>
          <Form.Control
             
            required
            as="textarea"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Enter Parameter Value"
          />
          <div className="text-end my-2">
            <button
              className="updateProfile"
              onClick={
                idData ? updateProtocolMetadataData : addProtocolMetadata
              }
              disabled={!name || !label || !value || loader}
            >
              {loader ? (
                <Spinner
                  className="me-1"
                  style={{ width: 15, height: 15 }}
                  animation="border"
                />
              ) : (
                <i
                  className={`fa-solid ${idData ? "fa-edit" : "fa-circle-plus"
                    } me-1`}
                ></i>
              )}
              {idData ? "Update" : "Add"} Protocol
            </button>
          </div>
          {statusCode && (
            <Alert variant={statusCode === 200 ? "success" : "danger"}>
              {responseMessage}
            </Alert>
          )}
        </div>
        <div>
          {result?.length > 0 ? (
            <>
              <Table
                ref={tableRef}
                id="resizable-table"
                striped
                className="custom-table"
                hover
              >
                <thead className="thead-sticky">
                  <tr>
                    <th
                      onMouseDown={(e) => onMouseDown(e, 0)}
                      style={{ width: "40px" }}
                    >
                      Sr.
                    </th>
                    <th onMouseDown={(e) => onMouseDown(e, 1)}>
                      Parameter Name
                    </th>
                    <th onMouseDown={(e) => onMouseDown(e, 2)}>
                      Parameter Label
                    </th>
                    <th onMouseDown={(e) => onMouseDown(e, 3)}>
                      Parameter Value
                    </th>
                    <th onMouseDown={(e) => onMouseDown(e, 5)}>
                      Created Date | Time
                    </th>
                    <th onMouseDown={(e) => onMouseDown(e, 6)}>
                      Update Date | Time
                    </th>
                    {viewTypeData === "ctms" &&
                      <th
                        style={{ width: 60 }}
                        onMouseDown={(e) => onMouseDown(e, 7)}
                      >
                        <div className="text-center">Remove</div>
                      </th>
                    }
                  </tr>
                </thead>
                <tbody ref={ref}>
                  {result.map((item, index) => (
                    <tr key={index} className="position-relative CP">
                      <td
                        onClick={() =>
                          updateProtocol(
                            item._id,
                            item.parameterName,
                            item.parameterLabel,
                            item.parameterValue,
                            item.type
                          )
                        }
                      >
                        {(pageNumber - 1) * itemsPerPage + index + 1}
                      </td>
                      <td
                        onClick={() =>
                          updateProtocol(
                            item._id,
                            item.parameterName,
                            item.parameterLabel,
                            item.parameterValue,
                            item.type
                          )
                        }
                      >
                        {item.parameterName}
                      </td>
                      <td
                        onClick={() =>
                          updateProtocol(
                            item._id,
                            item.parameterName,
                            item.parameterLabel,
                            item.parameterValue,
                            item.type
                          )
                        }
                      >
                        {item.parameterLabel}
                      </td>
                      <td
                        onClick={() =>
                          updateProtocol(
                            item._id,
                            item.parameterName,
                            item.parameterLabel,
                            item.parameterValue,
                            item.type
                          )
                        }
                      >
                        {item.parameterValue}
                      </td>
                      <td
                        onClick={() =>
                          updateProtocol(
                            item._id,
                            item.parameterName,
                            item.parameterLabel,
                            item.parameterValue,
                            item.type
                          )
                        }
                      >
                        <Moment format="DD MMM YYYY | HH:mm:ss">
                          {item.createdAt}
                        </Moment>
                      </td>
                      <td
                        onClick={() =>
                          updateProtocol(
                            item._id,
                            item.parameterName,
                            item.parameterLabel,
                            item.parameterValue,
                            item.type
                          )
                        }
                      >
                        <Moment format="DD MMM YYYY | HH:mm:ss">
                          {item.updatedAt}
                        </Moment>
                      </td>
                      {viewTypeData === "ctms" &&
                        <td>
                          <div className="text-center">
                            {item.type === "post" ? (
                              <i
                                title="Delete Protocol"
                                onClick={() =>
                                  deleteProtocolMetadataData(item._id)
                                }
                                className={`fa-solid fa-trash-can CP red`}
                              ></i>
                            ) : (
                              <i
                                title="No Delete Pre-Define Prorocol"
                                className={`fa-solid fa-trash-can gray`}
                              ></i>
                            )}
                          </div>
                        </td>
                      }
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="position-sticky bottom-0">
                <Pagination
                  itemsPerPage={itemsPerPage}
                  setItemsPerPage={setItemsPerPage}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  totalPage={totalPage}
                  totalItems={protocolList.length}
                />
              </div>
            </>
          ) : (
            <Col md={8} className="p-0">
              <div className="notFound">
                <h6>No Protocol Found</h6>
              </div>
            </Col>
          )}
        </div>
      </SplitPane>
    </div>
  );
};

export default ProtocolTrialSummeryMetadata;
