import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { CiCirclePlus } from "react-icons/ci";
import AddTrainingData from "./AddTrainingData";
import UseToggle from "../../../../Hooks/UseToggle";
import { useParams } from "react-router";
import { elearningServices } from "../../../../Services/elearningServices";
import Moment from "react-moment";
import UpdateTrainingData from "./UpdateTrainingData";

const TrainingDataManagement = ({ traningId, viewTypeData }) => {
  const { studyId } = useParams();
  const [question, setQuestion] = useState([]);
  const [questionData, setQuestionData] = useState("");
  const { toggle1, setToggle1, toggle2, setToggle2 } = UseToggle();
  const [mode, setMode] = useState("add");
  const getStudyELearningQuestionData = async () => {
    let data = await elearningServices.getStudyELearningQuestionData(
      traningId,
      studyId
    );
    setQuestion(data?.data || []);
  };

  const updateStudyELearningQuestionStatus = async (id, status) => {
    let userData = {};
    userData.id = id;
    userData.status = status;
    await elearningServices.updateStudyELearningQuestionStatus(userData);
    getStudyELearningQuestionData();
  };

  const addTraining = () => {
    setToggle1();
    setMode("add");
  };

  const upadateTraining = (item) => {
    setToggle2();
    setMode("update");
    setQuestionData(item);
  };

  useEffect(() => {
    getStudyELearningQuestionData();
  }, [traningId]);
  return (
    <>
      <div
        className="p-2 overflow-auto"
        style={{ height: "calc(100vh - 155px)" }}
      >
        <div className="d-flex gap-2 flex-wrap">
          {question?.map((item, index) =>
            item.type === "file" ? (
              <Card
                key={index}
                style={{ width: "18rem" }}
                className="shadow-sm   overflow-hidden"
              >
                <Card.Body className="p-0">
                  <div className="p-2 px-3 border-bottom d-flex justify-content-between">
                    <Card.Title className="fontSize12 m-0 fw-bold">
                      {item?.title}
                    </Card.Title>
                    <span className="text-muted ms-1" style={{ fontSize: 11 }}>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={item?.status === "active"}
                        onChange={() =>
                          updateStudyELearningQuestionStatus(
                            item?._id,
                            item?.status === "active" ? "inactive" : "active"
                          )
                        }
                      />
                    </span>
                  </div>
                  <div
                    className="px-3 py-1 overflow-auto CP"
                    title="Click for Edit"
                    style={{ height: 150 }}
                    onClick={() => upadateTraining(item)}
                  >
                    <Card.Text className="text-muted" style={{ fontSize: 12 }}>
                      {item?.description}
                    </Card.Text>
                  </div>
                  <div className="px-3 py-1">
                    <Row>
                      <Form.Group as={Col} md={6}>
                        <p
                          className="mb-1 fw-medium text-capitalize"
                          style={{ fontSize: 11 }}
                        >
                          Type :
                          <span
                            className="text-muted ms-1"
                            style={{ fontSize: 10 }}
                          >
                            {item?.extension}
                          </span>
                        </p>
                      </Form.Group>
                      <Form.Group as={Col} md={6}>
                        <p className="mb-1 fw-medium" style={{ fontSize: 11 }}>
                          Duration :
                          <span
                            className="text-muted ms-1"
                            style={{ fontSize: 10 }}
                          >
                            2:30hr
                          </span>
                        </p>
                      </Form.Group>
                    </Row>
                    <p className="mb-1 fw-medium" style={{ fontSize: 11 }}>
                      Categories :
                      <span
                        className="text-muted ms-1"
                        style={{ fontSize: 10 }}
                      >
                        Knowledge Checking Quiz
                      </span>
                    </p>
                  </div>
                  <div className="border-top bg-light p-2 px-3 d-flex justify-content-between">
                    <p className="mb-1 fw-medium" style={{ fontSize: 11 }}>
                      Last Update Date | Time :
                      <span
                        className="text-muted ms-1"
                        style={{ fontSize: 10 }}
                      >
                        <Moment format="DD MMM YYYY | HH:mm">
                          {item.updatedAt}
                        </Moment>
                      </span>
                    </p>
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <Card
                key={index}
                style={{ width: "18rem" }}
                className="shadow-sm   overflow-hidden"
              >
                <Card.Body className="p-0">
                  <div className="p-2 px-3 border-bottom d-flex justify-content-between">
                    <Card.Title className="fontSize12 m-0 fw-bold">
                      {item?.title}
                    </Card.Title>
                    <span className="text-muted ms-1" style={{ fontSize: 11 }}>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={item?.status === "active"}
                        onChange={() =>
                          updateStudyELearningQuestionStatus(
                            item?._id,
                            item?.status === "active" ? "inactive" : "active"
                          )
                        }
                      />
                    </span>
                  </div>
                  <div
                    className="px-3 py-1 overflow-auto CP"
                    title="Click for Edit"
                    style={{ height: 150 }}
                    onClick={() => upadateTraining(item)}
                  >
                    <Card.Text className="text-muted" style={{ fontSize: 12 }}>
                      {item?.description}
                    </Card.Text>
                  </div>
                  <div className="px-3 py-1">
                    <Row className="position-sticky bottom-0">
                      <Form.Group as={Col} md={6}>
                        <p className="mb-1 fw-medium" style={{ fontSize: 11 }}>
                          Type :
                          <span
                            className="text-muted ms-1"
                            style={{ fontSize: 10 }}
                          >
                            Quiz
                          </span>
                        </p>
                      </Form.Group>
                      <Form.Group as={Col} md={6}>
                        <p className="mb-1 fw-medium" style={{ fontSize: 11 }}>
                          No. of Ques :
                          <span
                            className="text-muted ms-1"
                            style={{ fontSize: 10 }}
                          >
                            {item.quizes.length}
                          </span>
                        </p>
                      </Form.Group>
                      <Form.Group as={Col} md={6}>
                        <p className="mb-1 fw-medium" style={{ fontSize: 11 }}>
                          Quiz for Ques :
                          <span
                            className="text-muted ms-1"
                            style={{ fontSize: 10 }}
                          >
                            {item?.questionNO}
                          </span>
                        </p>
                      </Form.Group>
                      <Form.Group as={Col} md={6}>
                        <p className="mb-1 fw-medium" style={{ fontSize: 11 }}>
                          Categories :
                          <span
                            className="text-muted ms-1"
                            style={{ fontSize: 10 }}
                          >
                            {item.category === "knowledgeQuiz"
                              ? "Knowladge Check Quiz"
                              : "Final Quiz"}
                          </span>
                        </p>
                      </Form.Group>
                    </Row>
                  </div>
                  <div className="border-top bg-light p-2 px-3 d-flex justify-content-between">
                    <p className="mb-1 fw-medium" style={{ fontSize: 11 }}>
                      Last Update Date | Time :
                      <span
                        className="text-muted ms-1"
                        style={{ fontSize: 10 }}
                      >
                        <Moment format="DD MMM YYYY | HH:mm">
                          {item.updatedAt}
                        </Moment>
                      </span>
                    </p>
                  </div>
                </Card.Body>
              </Card>
            )
          )}
          {viewTypeData === "ctms" &&
            <Card
              style={{ width: "18rem", height: "18rem" }}
              className="shadow-sm   overflow-hidden bg-light CP cardHoverAdd"
              title="Add New..."
              onClick={() => addTraining()}
            >
              <Card.Body className="p-0 hstack justify-content-center">
                <CiCirclePlus style={{ width: 40, height: 40 }} />
              </Card.Body>
            </Card>
          }
        </div>
      </div>
      <AddTrainingData
        Show={!toggle1}
        Hide={setToggle1}
        mode={mode}
        studyId={studyId}
        Title={`Add Training`}
        traningId={traningId}
        getStudyELearningQuestionData={getStudyELearningQuestionData}
      />
      <UpdateTrainingData
        Show={!toggle2}
        Hide={setToggle2}
        mode={mode}
        studyId={studyId}
        questionData={questionData}
        Title={viewTypeData === "ctms" ? `Update Training` : "View Training Question"}
        viewTypeData={viewTypeData}
        getStudyELearningQuestionData={getStudyELearningQuestionData}
      />
    </>
  );
};

export default TrainingDataManagement;
