import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

const StudyMetadata = () => {
  return (
    <>
      <Container
        fluid
        className="overflow-auto"
        style={{ height: "calc(100vh - 115px)" }}
      >
        <>
          <Row>
            <Form.Group as={Col} md="12" lg="12" sm="12" className="px-1">
              <div className="shadow-md border my-2 mx-1   overflow-hidden">
                <Form.Group className="border-bottom pb-2 p-2 hstack justify-content-between bg-light">
                  <Form.Label className="m-0 text-nowrap fw-bold fs-13">
                    Study Overview
                  </Form.Label>
                </Form.Group>
                <div className="p-2">
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Study Name :</b> 0001-22
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Study Description :</b> An Open-Label, Balanced,
                      Randomized, Single-Dose, Two-Treatment, Two-Sequence,
                      Two-Period, Two Way Crossover Oral
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Protocol Name :</b> 0001-22
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Standard Name :</b> StandardName
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Standard Version :</b> 3.2
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="m-0 text-nowrap">
                      <b>Language :</b> en
                    </Form.Label>
                  </Form.Group>
                </div>
              </div>
            </Form.Group>
          </Row>
        </>
      </Container>
    </>
  );
};

export default StudyMetadata;
