import React, { useRef, useCallback, useEffect } from "react";
import {
  ReactFlow,
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  useReactFlow,
  MiniMap,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";

import "./flowChartIndex.css";
import { DnDProvider, useDnD } from "./DnDContext";
import Sidebar from "./Sidebar";
import CustomNode from "./CustomNode";
import { cdiscServices } from "../../../../../../Services/CDISC/cdiscServices";

const initialNodes = [];

const DerivationData = ({ handleAddNewTab, domainId, studyId }) => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { screenToFlowPosition } = useReactFlow();
  const [type] = useDnD();

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      if (!type) {
        return;
      }

      const handleDrop = async () => {
        const position = screenToFlowPosition({
          x: event.clientX,
          y: event.clientY,
        });

        const newNode = {
          id: Math.random().toString().substr(2, 10),
          domainId: domainId,
          studyId: studyId,
          type: "custom",
          toolType: `${type}`,
          position,
          data: { label: `${type}` },
        };

        let userData = {}
        userData.studyId = studyId
        userData.derivation = newNode
        await cdiscServices.addNewDomainDerivation(userData);
        setNodes((nds) => nds.concat(newNode));
      };
      handleDrop();
    },
    [screenToFlowPosition, type]
  );

  const handleRemoveNode = async (id) => {
    let data = await cdiscServices.deleteDomainDerivationData(studyId, id)
    if (data?.statusCode === 200) {
      getDomainDerivationList()
    }
  };

  const getDomainDerivationList = async () => {
    let data = await cdiscServices.getDomainDerivationList(studyId, domainId)
    setNodes(data?.data || [])
    setEdges(data?.edgeData || [])
  }

  const updateDomainDerivation = async (changedNodes) => {
    onNodesChange(changedNodes);
    for (const node of changedNodes) {
      if (node.type === 'position') {
        const updatedNodeData = {
          derivationId: node.id,
          position: node.position,
          measured: node.measured,
          studyId: studyId,
        };
        await cdiscServices.updateDomainDerivation(updatedNodeData);
      }
    }
  };

  const updateEdgeDomainDerivation = async (edges) => {
    for (const node of edges) {
      const updatedNodeData = {
        derivationId: node.source,
        target: node.target,
        edgeId: node.id,
        studyId: studyId,
      };
      await cdiscServices.updateDomainDerivation(updatedNodeData);
    }
  };
  useEffect(() => {
    updateEdgeDomainDerivation(edges)
  }, [studyId, edges]);

  useEffect(() => {
    getDomainDerivationList();
  }, [studyId, domainId]);

  return (
    <div className="dndflow position-relative h-100">
      <Sidebar />
      <div className="reactflow-wrapper" ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes?.map((node) => ({
            ...node,
            data: {
              ...node.data,
              onRemove: handleRemoveNode,
              getDomainDerivationList: getDomainDerivationList,
              addTabs: handleAddNewTab,
              domainId: domainId,
              id: node.id,
              derivationId: node.derivationId,
              primaryDataType: node.primaryDataType,
              primaryDataset: node.primaryDataset,
              secondaryDataType: node.secondaryDataType,
              secondaryDataset: node.secondaryDataset,
              primaryKey: node.primaryKey,
              logStatus: node.logStatus,
              logs: node.logs,
              procedureType: node.procedureType,
              toolType: node.toolType,
              sortData: node.sortData,
              byVar: node.byVar,
              vars: node.vars,
              idVar: node.idVar,
              prefix: node.prefix,
              suffix: node.suffix,
              retainColumns: node.retainColumns,
              retainCustoms: node.retainCustoms,
              newColumns: node.newColumns,
              keepColumns: node.keepColumns,
              keepCustoms: node.keepCustoms,
              dropColumns: node.dropColumns,
              dropCustoms: node.dropCustoms,
              statements: node.statements,
              _id: node._id,
              label: node.label || node?.data?.label,
              derivedData: nodes || []
            },
          }))}
          edges={edges?.map((edge) => ({
            ...edge,
            markerEnd: {
              type: 'arrowclosed',
              color: '#198754',
            },
            style: { stroke: '#16f474', strokeWidth: 1 },
          }))}
          onNodesChange={updateDomainDerivation}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onDrop={onDrop}
          onDragOver={onDragOver}
          nodeTypes={{ custom: CustomNode }}
          fitView
        >
          <Controls />
          <MiniMap />
        </ReactFlow>
      </div>
    </div>
  );
};

const DerivationDataWrapper = (props) => (
  <ReactFlowProvider>
    <DnDProvider>
      <DerivationData {...props} />
    </DnDProvider>
  </ReactFlowProvider>
);

export default DerivationDataWrapper;