import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { Badge, Col, Form, Offcanvas, Row, Spinner } from "react-bootstrap";
import { elearningServices } from "../../../../Services/elearningServices";

const UpdateTrainingData = ({
  Show,
  Hide,
  Title,
  questionData,
  getStudyELearningQuestionData,
  viewTypeData
}) => {
  let [loader, setLoader] = useState(false);
  let [title, setTitle] = useState("");
  let [description, setDescription] = useState("");
  let [type, setType] = useState("");
  let [fileType, setFileType] = useState("");
  let [file, setFile] = useState(null);
  let [categories, setCategories] = useState("");
  let [questionNO, setQuestionNO] = useState("");
  const [questions, setQuestions] = useState([
    { question: "", options: ["", "", "", ""], answerType: "", answered: "" },
  ]);
  let StudyMode = JSON.parse(localStorage.getItem("StudyMode"));

  const handleUpload = (e) => {
    setFile(e.taget.value);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].question = value;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (qIndex, oIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options[oIndex] = value;
    setQuestions(newQuestions);
  };

  const handleAnswerTypeChange = (qIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].answerType = value;
    setQuestions(newQuestions);
  };

  const handleAnsweredChange = (qIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].answered = value;
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      { question: "", options: ["", "", "", ""], answerType: "" },
    ]);
  };

  const deleteQuestion = (index) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const addOption = (qIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options.push("");
    setQuestions(newQuestions);
  };

  const deleteOption = (qIndex, oIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options = newQuestions[qIndex].options.filter(
      (_, i) => i !== oIndex
    );
    setQuestions(newQuestions);
  };

  const updateStudyELearningQuestionData = async () => {
    setLoader(true);
    let userData = {};
    userData.id = questionData?._id;
    userData.title = title;
    userData.description = description;
    userData.type = type;
    userData.extension = fileType;
    userData.questionNO = questionNO;
    userData.category = categories;
    userData.quizes = questions;
    let data = await elearningServices.updateStudyELearningQuestionData(
      userData
    );
    if (data?.statusCode === 200) {
      getStudyELearningQuestionData();
    }
    setLoader(false);
  };

  const deleteStudyELearningQuestionData = async () => {
    await elearningServices.deleteStudyELearningQuestionData(questionData?._id);
    getStudyELearningQuestionData();
    Hide();
  };

  useEffect(() => {
    setTitle(questionData?.title);
    setDescription(questionData?.description);
    setType(questionData?.type);
    setFileType(questionData?.extension);
    setCategories(questionData?.category);
    setQuestionNO(questionData?.questionNO);
    setQuestions(questionData?.quizes);
  }, [questionData]);

  return (
    <div>
      <Offcanvas
        show={Show}
        onHide={Hide}
        placement="end"
        style={{ width: 650 }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="p-3 overflow-auto"
            style={{ height: "calc(100vh - 89px)" }}
          >
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold fontSize12">
                Title <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={title}
                 
                placeholder="Enter Title"
                disabled={viewTypeData !== "ctms"}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold fontSize12">
                Description <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                value={description}
                 
                placeholder="Enter Description"
                disabled={viewTypeData !== "ctms"}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold fontSize12">
                Type <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                disabled={viewTypeData !== "ctms"}
                 
                onChange={(e) => setType(e.target.value)}
              >
                <option value="">Select Type</option>
                <option selected={type === "file" && "file"} value="file">
                  File
                </option>
                <option selected={type === "quiz" && "quiz"} value="quiz">
                  Quiz
                </option>
              </Form.Select>
            </Form.Group>
            {type === "file" && (
              <>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold fontSize12">
                    File Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    disabled={viewTypeData !== "ctms"}
                     
                    onChange={(e) => setFileType(e.target.value)}
                  >
                    <option value="">Select File Type</option>
                    <option
                      selected={fileType === "video" && "video"}
                      value="video"
                    >
                      Video
                    </option>
                    <option selected={fileType === "pdf" && "pdf"} value="pdf">
                      Pdf
                    </option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold fontSize12">
                    File Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    disabled={viewTypeData !== "ctms" && !fileType}
                    type="file"
                    onChange={handleUpload}
                     
                    accept={`${fileType === "video" ? ".mp4" : ".pdf"}`}
                  />
                </Form.Group>
              </>
            )}
            {type === "quiz" && (
              <>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold fontSize12">
                    No.of Quiz for Question{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    disabled={viewTypeData !== "ctms"}
                    type="number"
                    placeholder="Enter No. of Quiz for Question"
                     
                    value={questionNO}
                    onChange={(e) => setQuestionNO(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold fontSize12">
                    Categories <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    disabled={viewTypeData !== "ctms"}
                     
                    onChange={(e) => setCategories(e.target.value)}
                  >
                    <option value="">Select Categories</option>
                    <option
                      selected={categories === "finalQuiz" && "finalQuiz"}
                      value="finalQuiz"
                    >
                      Final Quiz
                    </option>
                    <option
                      selected={
                        categories === "knowledgeQuiz" && "knowledgeQuiz"
                      }
                      value="knowledgeQuiz"
                    >
                      Knowledge Checking Quiz
                    </option>
                  </Form.Select>
                </Form.Group>
              </>
            )}
            {type === "quiz" && categories && (
              <Form.Group className="mb-2">
                <div className="border   p-3 mt-4 pt-4 position-relative">
                  <Badge
                    className="position-absolute   text-muted bg-success-subtle"
                    style={{ top: -10 }}
                  >
                    Quiz
                  </Badge>
                  <div>
                    {questions.map((q, qIndex) => (
                      <div key={qIndex} className="mb-2">
                        <div className="mt-2">
                          <div className="shadow-sm p-2 border  ">
                            <Form.Group className="mb-2">
                              <div className="hstack justify-content-between">
                                <Form.Label className="fw-bold my-1 fontSize12">
                                  Question {qIndex + 1}{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <i
                                  className="fa-solid fa-trash-can text-danger fontSize12 CP"
                                  title="Delete Question"
                                  onClick={() => deleteQuestion(qIndex)}
                                ></i>
                              </div>
                              <Form.Control
                                disabled={viewTypeData !== "ctms"}
                                as="textarea"
                                 
                                value={q.question}
                                placeholder="Type Question"
                                onChange={(e) =>
                                  handleQuestionChange(qIndex, e.target.value)
                                }
                              />
                            </Form.Group>
                            <Row>
                              {q.options.map((option, oIndex) => (
                                <React.Fragment key={oIndex}>
                                  <Form.Group as={Col} md={3} className="mb-2">
                                    <Form.Label className="fw-bold mb-1 fontSize12">
                                      Option {oIndex + 1}{" "}
                                      <span className="text-danger">*</span>
                                    </Form.Label>
                                  </Form.Group>
                                  <Form.Group
                                    as={Col}
                                    md={9}
                                    className="hstack gap-2 mb-2"
                                  >
                                    <Form.Control
                                      disabled={viewTypeData !== "ctms"}
                                      type="text"
                                       
                                      value={option}
                                      placeholder="Type Answer"
                                      onChange={(e) =>
                                        handleOptionChange(
                                          qIndex,
                                          oIndex,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <i
                                      className="fa-solid fa-trash-can text-danger fontSize12 CP"
                                      title={`Delete Option ${oIndex + 1}`}
                                      onClick={() =>
                                        deleteOption(qIndex, oIndex)
                                      }
                                    ></i>
                                  </Form.Group>
                                </React.Fragment>
                              ))}
                              {(StudyMode === "DBP MODE" && viewTypeData === "ctms") &&
                                <Form.Group className="mb-2">
                                  <button
                                    className="border fontSize12   p-1 px-2 updateProfile"
                                    onClick={() => addOption(qIndex)}
                                  >
                                    + Add More Options
                                  </button>
                                </Form.Group>
                              }
                            </Row>
                            <Form.Group className="mb-2">
                              <Form.Label className="mb-1 fw-bold fontSize12">
                                Answer Type{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                disabled={viewTypeData !== "ctms"}
                                 
                                value={q.answerType}
                                onChange={(e) =>
                                  handleAnswerTypeChange(qIndex, e.target.value)
                                }
                              >
                                <option value="">Select Answer Type</option>
                                <option value="singleSelect">
                                  Single Select
                                </option>
                                <option value="multiSelect">
                                  Multi Select
                                </option>
                              </Form.Select>
                            </Form.Group>
                            {q.answerType === "multiSelect" && (
                              <Form.Group className="mb-2">
                                <Form.Label className="mb-1 fw-bold fontSize12">
                                  Answer <span className="text-danger">*</span>
                                </Form.Label>
                                <Multiselect
                                  disabled={viewTypeData !== "ctms"}
                                  displayValue="label"
                                  options={q.options.map((option, i) => ({
                                    label: option,
                                    value: option,
                                  }))}
                                />
                              </Form.Group>
                            )}
                            {q.answerType === "singleSelect" && (
                              <Form.Group className="mb-2">
                                <Form.Label className="mb-1 fw-bold fontSize12">
                                  Answer <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Select
                                  disabled={viewTypeData !== "ctms"}
                                  value={q.answered}
                                  onChange={(e) =>
                                    handleAnsweredChange(qIndex, e.target.value)
                                  }
                                   
                                >
                                  <option value="">Select Answer</option>
                                  {q.options.map((option, oIndex) => (
                                    <option key={oIndex} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    {(StudyMode === "DBP MODE" && viewTypeData === "ctms") &&
                      <button
                        onClick={addQuestion}
                        className="submitbutton   border mt-3"
                      >
                        <i className="fa-solid fa-square-plus fontSize12 CP me-1"></i>{" "}
                        Add Question
                      </button>
                    }
                  </div>
                </div>
              </Form.Group>
            )}
          </div>
          <div className="p-2 bg-light shadow-sm hstack justify-content-end gap-2">
            <button
              onClick={() => {
                deleteStudyELearningQuestionData();
              }}
              className="border border-danger btn btn-outline-danger fontSize12   p-1 px-2"
            >
              Delete
            </button>
            <button
              onClick={updateStudyELearningQuestionData}
              className="border fontSize12   p-1 px-2 updateProfile"
            >
              {loader ? (
                <Spinner
                  className="me-1"
                  style={{ width: 15, height: 15 }}
                  animation="border"
                />
              ) : (
                <i className={` fa-solid fa-square-pen me-2`}></i>
              )}
              {`Update Training`}
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default UpdateTrainingData;
