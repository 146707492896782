import React from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../../../../Styles/SignatureModal.scss";
import ApeendInputsComponent from "../../../../ApeendInputsComponent/ApeendInputsComponent";

const UpdateJsonData = ({
  SHOW,
  HIDE,
  Title,
  Label1,
  Label2,
  Label3,
  Label4,
  Label5,
  PlaceHolder,
  PlaceHolder3,
  PlaceHolder4,
  PlaceHolder5,
  Value1,
  Value2,
  Value3,
  Value4,
  Value5,
  type1,
  type2,
  type3,
  type4,
  type5,
  inputField3,
  inputField4,
  inputField5,
  OnChange1,
  OnChange2,
  OnChange3,
  OnChange4,
  OnChange5,
  DropDown1,
  DropDown2,
  DropDown3,
  DropDown4,
  DropdownLabel1,
  DropdownLabel2,
  DropdownLabel3,
  DropdownLabel4,
  DropValue3,
  DropValue4,
  DropdownPlaceHolder1,
  DropdownPlaceHolder2,
  DropdownPlaceHolder3,
  DropdownPlaceHolder4,
  DropdownValue1,
  DropdownValue2,
  DropdownOnChange1,
  DropdownOnChange2,
  DropdownOnChange3,
  DropdownOnChange4,
  ageGroupList,
  genderList,
  HistoryData,
  onChangeInput,
  UpdateHistoryOnChange,
  SubmitTitle,
  SubmitForm,
  studyDynamicColumnData,

  formValues,
  handleChange,
  removeFormFields,
  addFormFields,
  UpdateRow,
  AddRowLabel,
  UpdateRowLabel,

  Radio,
  CommentValue,
  CommentOnChange,
  UpdateHistory,
  HistoryValue,
  HistoryOnChangeInput,
  Class,
  siteList,
  SiteDropDown,
  SiteDropdownLabel,
  SiteDropdownOnChange,
  SiteDropdownPlaceHolder,
  SiteDropdownValue,
  SubjectGender,
  SubjectGenderDropdownChange,
  subjectGenderValue,
}) => {
  const showLabel = (label, htmlFor) => (
    <Form.Label htmlFor={htmlFor} className="mb-1 fw-bold d-flex">
      {label}
    </Form.Label>
  );

  return (
    <>
      <Modal
        className={SHOW ? Class : `d-none ${Class}`}
        show={SHOW}
        onHide={HIDE}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">{Title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="w-100">
            {Radio && (
              <div className="signInTerms">
                <span className="mb-3">
                  You are required to provide a signature for this activity in
                  order to fully complete it. This records that you are signing
                  off theat you have taken, and completed this online review
                  activity. This will resilt in the completion record being
                  recorded in your review history.
                </span>
              </div>
            )}

            {OnChange1 && (
              <>
                {showLabel(Label1, "one")}

                <Form.Control
                  type={type1}
                  className="  mb-3"
                  id="one"
                  value={Value1}
                  onChange={OnChange1}
                  required
                />
              </>
            )}

            {OnChange2 && (
              <>
                {showLabel(Label2, "two")}

                <input
                  type={type2}
                  className="form-control inputboxborder mb-3"
                  id="two"
                  value={Value2}
                  onChange={OnChange2}
                  required
                />
              </>
            )}

            {Radio && (
              <>
                {showLabel("Comment", "comment")}

                <textarea
                  className="form-control inputboxborder mb-3"
                  cols="30"
                  rows="3"
                  id="comment"
                  PlaceHolder="Enter Comments"
                  value={CommentValue}
                  onChange={CommentOnChange}
                />
              </>
            )}

            {inputField3 && (
              <>
                {showLabel(Label3, "three")}

                <input
                  type={type3}
                  className="form-control inputboxborder mb-3"
                  id="three"
                  value={Value3}
                  onChange={OnChange3}
                  placeholder={PlaceHolder3}
                  required
                />
              </>
            )}

            {SiteDropDown && (
              <>
                {showLabel(SiteDropdownLabel)}

                <Form.Select
                  className="  mb-3"
                  id="autoSizingSelect"
                  onChange={SiteDropdownOnChange}
                >
                  <option>{SiteDropdownPlaceHolder}</option>
                  {siteList?.map((item, key) => (
                    <option
                      selected={
                        SiteDropdownValue === item.gender ? true : false
                      }
                      key={key}
                      value={item.studyUniqueId}
                    >
                      {item.siteIdLabel}
                    </option>
                  ))}
                </Form.Select>
              </>
            )}

            {inputField4 && (
              <>
                {showLabel(Label4, "four")}

                <Form.Control
                  type={type4}
                  className="  mb-3"
                  id="four"
                  value={Value4}
                  onChange={OnChange4}
                  placeholder={PlaceHolder4}
                  required
                />
              </>
            )}

            {SubjectGender && (
              <>
                {showLabel("Subject Gender")}

                <Form.Select
                  className="  mb-3"
                  id="autoSizingSelect"
                  onChange={SubjectGenderDropdownChange}
                >
                  <option>{"Select Subject Gender"}</option>
                  <option selected={subjectGenderValue === "male"} value="male">
                    {"Male"}
                  </option>
                  <option
                    selected={subjectGenderValue === "female"}
                    value="female"
                  >
                    {"Female"}
                  </option>
                  <option
                    selected={subjectGenderValue === "unknown"}
                    value="unknown"
                  >
                    {"Unknown"}
                  </option>
                  <option
                    selected={subjectGenderValue === "undifferentiated"}
                    value="undifferentiated"
                  >
                    {"Undifferentiated"}
                  </option>
                  <option
                    selected={subjectGenderValue === "other"}
                    value="other"
                  >
                    {"Other"}
                  </option>
                </Form.Select>
              </>
            )}

            {DropDown1 && (
              <>
                {showLabel(DropdownLabel1)}

                <Form.Select
                  className="  mb-3"
                  id="autoSizingSelect"
                  onChange={DropdownOnChange1}
                >
                  <option>{DropdownPlaceHolder1}</option>
                  {genderList?.map((item, key) => (
                    <option
                      selected={DropdownValue1 === item.gender ? true : false}
                      key={key}
                      value={item.gender}
                    >
                      {item.gender}
                    </option>
                  ))}
                </Form.Select>
              </>
            )}

            {DropDown2 && (
              <>
                {showLabel(DropdownLabel2)}
                <Form.Select
                  className="  mb-3"
                  id="autoSizingSelect"
                  onChange={DropdownOnChange2}
                >
                  <option>{DropdownPlaceHolder2}</option>
                  {ageGroupList?.map((item, key) => (
                    <option
                      selected={DropdownValue2 === item.ageGroup ? true : false}
                      key={key}
                      value={item.ageGroup}
                    >
                      {`${item.ageGroup} Year`}
                    </option>
                  ))}
                </Form.Select>
              </>
            )}

            {studyDynamicColumnData
              ? studyDynamicColumnData?.map(
                  (
                    {
                      name,
                      types,
                      valueData,
                      history,
                      formUniqueId,
                      fieldUniqueId,
                    },
                    i
                  ) =>
                    formUniqueId && fieldUniqueId ? (
                      ""
                    ) : (
                      <>
                        <div key={i}>
                          {AddRowLabel && showLabel(name)}

                          {UpdateRowLabel && showLabel(name)}

                          <Form.Control
                            key={i}
                            type={types}
                            className="  mb-3 w-100"
                            name="valueData"
                            value={valueData}
                            onChange={(e) => onChangeInput(e, i)}
                            placeholder={PlaceHolder}
                            required
                          />
                        </div>
                        {HistoryData && (
                          <div key={i}>
                            {AddRowLabel && showLabel("Comment")}

                            {UpdateRowLabel && showLabel("Comment")}

                            <Form.Control
                              key={i}
                              as={"textarea"}
                              className="  mb-3 w-100"
                              name="history"
                              value={history}
                              onChange={(e) => UpdateHistoryOnChange(e, i)}
                              placeholder={"Enter Comment"}
                              required
                            />
                          </div>
                        )}
                      </>
                    )
                )
              : ""}

            {!UpdateRow && (
              <ApeendInputsComponent
                formValues={formValues}
                handleChange={handleChange}
                removeFormFields={removeFormFields}
                addFormFields={addFormFields}
              />
            )}

            {DropDown3 && (
              <>
                {showLabel(DropdownLabel3)}

                <Form.Select
                  className="  mb-3"
                  id="autoSizingSelect"
                  onChange={DropdownOnChange3}
                >
                  <option>{DropdownPlaceHolder3}</option>
                  <option
                    selected={DropValue3 === "yes" ? true : false}
                    value={"yes"}
                  >
                    Yes
                  </option>
                  <option
                    selected={DropValue3 === "no" ? true : false}
                    value={"no"}
                  >
                    No
                  </option>
                </Form.Select>
              </>
            )}

            {DropDown4 && (
              <>
                {showLabel(DropdownLabel4)}

                <Form.Select
                  className="  mb-3"
                  id="autoSizingSelect"
                  onChange={DropdownOnChange4}
                >
                  <option>{DropdownPlaceHolder4}</option>
                  <option
                    selected={DropValue4 === "afterFirstVisits" ? true : false}
                    value={"afterFirstVisits"}
                  >
                    After First Visits
                  </option>
                  <option
                    selected={DropValue4 === "anyTime" ? true : false}
                    value={"anyTime"}
                  >
                    Any Time
                  </option>
                </Form.Select>
              </>
            )}

            {UpdateHistory && (
              <>
                {showLabel("History")}

                <Form.Control
                  className="  mb-3 w-100"
                  name="valueData"
                  value={HistoryValue}
                  onChange={(e) => HistoryOnChangeInput(e)}
                  placeholder={"Update History"}
                  required
                />
              </>
            )}

            {inputField5 && (
              <>
                {showLabel(Label5, "five")}

                <Form.Control
                  type={type5}
                  className="  mb-3"
                  id="five"
                  value={Value5}
                  onChange={OnChange5}
                  placeholder={PlaceHolder5}
                  required
                />
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div onClick={SubmitForm}>
            <Button onClick={HIDE} className="submitbutton">
              {SubmitTitle}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateJsonData;
