import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Moment from "react-moment";
import SplitPane from "react-split-pane";
import { GoSearch } from "react-icons/go";
import { Collapse, Form, InputGroup, Spinner, Table } from "react-bootstrap";
import { rbqmStudyServices } from "../../../../Services/rbqmServices/rbqmStudyServices";
import ViewFormule from "./ViewFormule";
import UseToggle from "../../../../Hooks/UseToggle";
import Pagination from "../../../ETMF/Pagination/Pagination";
import { useParams } from "react-router";

const RiskCategory = () => {
  let [id, setId] = useState("");
  let [riskCategory, setRiskCategory] = useState("");
  let [loader, setLoader] = useState(false);
  let [openForm, setOpenForm] = useState("");
  let [type, setType] = useState("");
  let [riskCategoryList, setRiskCategoryList] = useState([]);
  let [openFilter, setOpenFilter] = useState(false);
  let { toggle, setToggle } = UseToggle();
  let [formuleValue, setFormuleValue] = useState("");
  let { studyId } = useParams("");
  const addNewRiskCategory = async () => {
    setLoader(true);
    let userData = {};
    userData.studyId = studyId;
    userData.name = riskCategory;
    userData.type = type;
    let data = await rbqmStudyServices.addNewRiskCategory(userData);
    if (data?.statusCode === 200) {
      getRiskCategoryList();
      setType("");
      setRiskCategory("");
    }
    setLoader(false);
  };

  const getRiskCategoryList = async () => {
    setLoader(true);
    let data = await rbqmStudyServices.getRiskCategoryList(studyId);
    setRiskCategoryList(data?.data);
    setLoader(false);
  };

  const handleUpdateRiskCategory = (e, item) => {
    setId(item?._id);
    setType("custom");
    setRiskCategory(item?.name);
    setOpenForm(true);
  };

  const clearForm = () => {
    setRiskCategory("");
    setId("");
  };

  const updateRiskCategory = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Risk Category?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        let userData = {};
        userData.studyId = studyId;
        userData.id = id;
        userData.name = riskCategory;
        let data = await rbqmStudyServices.updateRiskCategory(userData);
        if (data?.statusCode === 200) {
          getRiskCategoryList();
        }
        setLoader(false);
      }
    });
  };

  const deleteRiskCategory = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this RiskCategory?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await rbqmStudyServices.deleteRiskCategories(studyId, id);
        if (data?.statusCode === 200) {
          getRiskCategoryList();
        }
      }
    });
  };

  const handleViewRiskArea = (e, item) => {
    setId(item?._id);
    setFormuleValue(item?.formula || "");
    setToggle();
  };

  useEffect(() => {
    getRiskCategoryList();
  }, [studyId]);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = riskCategoryList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(riskCategoryList?.length / itemsPerPage);

  return (
    <>
      <div className="hstack justify-content-between border-bottom px-1">
        <div className="hstack gap-2 toolbar" style={{ padding: "5px" }}>
          <i
            title="Add"
            class="fa-solid fa-square-plus CP p-1 fontSize12"
            onClick={() => setOpenForm(!openForm)}
            style={{ borderRadius: 3 }}
          ></i>
          <div className="vr"></div>
          <i
            title="Add"
            class="fa-solid fa-filter CP p-1 fontSize12"
            onClick={() => setOpenFilter(!openFilter)}
            style={{ borderRadius: 3 }}
          ></i>
          <Collapse in={openFilter} dimension="width">
            <div>
              <InputGroup style={{ width: 200 }}>
                <Form.Control
                  type="search"
                  className="px-2 rounded-0"
                  placeholder="Search..."
                  style={{ fontSize: 10, padding: "0px" }}
                />
                <button
                  className="tabButtons border fs-13"
                  style={{
                    padding: "0px 5px",
                    borderRadius: "0 3px 3px 0",
                  }}
                >
                  <GoSearch />
                </button>
              </InputGroup>
            </div>
          </Collapse>
        </div>
      </div>
      <SplitPane
        split="horizontal"
        style={{ height: "calc(100vh - 185px)" }}
        className="position-relative"
        defaultSize={"100%"}
      >
        <SplitPane minSize={20} defaultSize={openForm ? "20%" : "100%"}>
          {openForm && (
            <div className="p-2">
              <Form.Group className="mb-2">
                <div className="hstack justify-content-between">
                  <Form.Label className="fw-bold mb-1">
                    Type <span className="text-danger">*</span>
                  </Form.Label>
                  {id && (
                    <div
                      title="Add Mew Risk Category"
                      className="w-auto p-0 cursor-pointer"
                      style={{ fontSize: 11 }}
                      onClick={() => clearForm()}
                    >
                      <i className="fa-solid fa-square-plus"></i> Add New
                    </div>
                  )}
                </div>
                <Form.Select
                  disabled={id}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="">Select Type</option>
                  <option selected={type === "pull"} value="pull">
                    Pull
                  </option>
                  <option selected={type === "custom"} value="custom">
                    Custom
                  </option>
                </Form.Select>
              </Form.Group>
              {type === "custom" && (
                <Form.Group className="mb-2">
                  <Form.Label className="fw-bold mb-1">
                    Risk Category <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={riskCategory}
                    className="rounded-1"
                    placeholder="Enter Risk Category Name"
                    onChange={(e) => setRiskCategory(e.target.value)}
                  />
                </Form.Group>
              )}
              <div className="text-end">
                <button
                  className="submitbutton px-2 fontSize12"
                  disabled={!type || (type === "custom" && !riskCategory)}
                  onClick={() =>
                    id ? updateRiskCategory() : addNewRiskCategory()
                  }
                >
                  {loader ? (
                    <Spinner
                      animation="border"
                      style={{ width: 15, height: 15 }}
                      className="me-2"
                    />
                  ) : null}
                  {id ? "Update" : "Add"} Risk Category
                </button>
              </div>
            </div>
          )}
          {loader ? (
            <div className="hstack justify-content-center h-100">
              <Spinner />
            </div>
          ) : result?.length > 0 ? (
            <div>
              <Table id="resizable-table" striped className="m-0 custom-table">
                <thead className="thead-sticky">
                  <tr>
                    <th style={{ width: 55 }} className="text-center">
                      S No.
                    </th>
                    <th>Risk Category Name</th>
                    <th>Created Date | Time</th>
                    <th>Updated Date | Time</th>
                    <th style={{ width: 70 }} className="text-center">
                      Formule
                    </th>
                    <th style={{ width: 70 }} className="text-center">
                      Update
                    </th>
                    <th style={{ width: 70 }} className="text-center">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {result?.length > 0 &&
                    result?.map((item, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>
                          <Moment format="DD MMM YYYY | HH:mm" withTitle>
                            {item.createdAt}
                          </Moment>
                        </td>
                        <td>
                          <Moment format="DD MMM YYYY | HH:mm" withTitle>
                            {item.updatedAt}
                          </Moment>
                        </td>
                        <td className="text-center" title="View Formule">
                          <button
                            className="text-white border-success bg-success border-0 fs-10"
                            onClick={(e) => handleViewRiskArea(e, item)}
                          >
                            View
                          </button>
                        </td>
                        <td className="text-center" title="View Indicators">
                          <button
                            className="text-white border-success bg-success border-0 fs-10"
                            onClick={(e) => handleUpdateRiskCategory(e, item)}
                          >
                            Update
                          </button>
                        </td>
                        <td className="text-center" title="View Indicators">
                          <button
                            className="text-white border-danger bg-danger border-0 fs-10"
                            onClick={(e) => deleteRiskCategory(e, item._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <div className="position-sticky bottom-0">
                <Pagination
                  totalPage={totalPage}
                  pageNumber={pageNumber}
                  itemsPerPage={itemsPerPage}
                  totalItems={riskCategoryList?.length}
                  setPageNumber={setPageNumber}
                  setItemsPerPage={setItemsPerPage}
                />
              </div>
            </div>
          ) : (
            <div className="hstack justify-content-center h-100">
              <div className="noRecordFound">Risk Category List not found!</div>
            </div>
          )}
        </SplitPane>
      </SplitPane>
      <ViewFormule
        Show={!toggle}
        Hide={setToggle}
        studyId={studyId}
        setFormuleValue={setFormuleValue}
        getRiskCategoryList={getRiskCategoryList}
        formuleValue={formuleValue}
        setLoader={setLoader}
        Title="View Formule"
        id={id}
      />
    </>
  );
};

export default RiskCategory;
