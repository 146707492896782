import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { CiFilter, CiViewTable } from "react-icons/ci";
import SplitPane from "react-split-pane";
import { BiCollapse, BiExpand } from "react-icons/bi";
import DictionariesTableData from "./DictionariesTableData";
import DictionariesTableProperties from "./DictionariesTableProperties";
import DictionariesFilter from "./DictionariesFilter";
import UseToggle from "../../../../../Hooks/UseToggle";

const Dictionaries = ({ onMouseDown, tableRef }) => {
  const [loader, setLoader] = useState(false);
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const CodeList = Array.from({ length: 15 }, (_, index) => ({
    _id: index + 1,
    codeListId: ["List A", "List B", "List C"][Math.floor(Math.random() * 3)],
    codeListName: `Code List ${Math.floor(Math.random() * 15) + 1}`,
    nciClodeListCode: `NCI${Math.floor(10000 + Math.random() * 90000)}`,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  }));

  const HeaderTools = [
    {
      title: "View Table Properties",
      icon: <CiViewTable />,
      onClick: setToggle,
    },
    { title: "Filter", icon: <CiFilter />, onClick: setToggle1 },
  ];

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div
        className="hstack justify-content-between w-100 border-bottom"
        style={{ padding: "4.6px 9px" }}
      >
        <div className="hstack gap-2">
          {HeaderTools?.map((button, index) => (
            <React.Fragment key={index}>
              <button
                title={button.title}
               className="tabButtons fontSize12 border-0  "
                style={{ padding: "1px 5px" }}
                onClick={button.onClick}
              >
                {button.icon}
              </button>
              {index < 1 && <div className="vr"></div>}
            </React.Fragment>
          ))}
        </div>
        <button
         className="tabButtons fontSize12 border-0  "
          title={!isFullScreen ? "Full Mode" : "Original Mode"}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <BiCollapse /> : <BiExpand />}
        </button>
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize={"100%"}
        >
          <SplitPane split="vertical" minSize={20} defaultSize={"100%"}>
            <>
              {loader ? (
                <div className="hstack justify-content-center h-100 w-100">
                  <Spinner />
                </div>
              ) : CodeList?.length === 0 ? (
                <div className="hstack justify-content-center h-100 w-100">
                  Data not found!
                </div>
              ) : (
                <DictionariesTableData
                  CodeList={CodeList}
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                />
              )}
            </>
          </SplitPane>
        </SplitPane>
      </div>
      <DictionariesTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
      <DictionariesFilter
        Show={!toggle1}
        Hide={setToggle1}
        Title={"Filter Dictionaries"}
      />
    </div>
  );
};

export default Dictionaries;
