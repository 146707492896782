import React from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";

const STDMCommentTableProperties = ({
  Show,
  Hide,
  Title,
  selectAll,
  handleSelectAll,
  selectedNames,
  handleCheckboxChange,
  handleSubmitTableProperty,
  totalColumnLength,
  studyDynamicColumn,
}) => {
  return (
    <>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title} <span className="fs-10">-[Check for hide]</span></Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form.Check
            type="checkbox"
            checked={selectAll || totalColumnLength === selectedNames?.length}
            onChange={handleSelectAll}
            label="Select All"
          />
          <Form.Check
            type="checkbox"
            name="Code List Id"
            checked={selectedNames?.includes("Code List Id")}
            onChange={() => handleCheckboxChange("Code List Id")}
            id="edit_label"
            label="Code List Id"
          />
          <Form.Check
            type="checkbox"
            name="Code List Name"
            checked={selectedNames?.includes("Code List Name")}
            onChange={() => handleCheckboxChange("Code List Name")}
            id="edit_label"
            label="Code List Name"
          />
          <Form.Check
            type="checkbox"
            name="NCI Clode List Code"
            checked={selectedNames?.includes("NCI Clode List Code")}
            onChange={() => handleCheckboxChange("NCI Clode List Code")}
            id="edit_label"
            label="NCI Clode List Code"
          />
          <Form.Check
            type="checkbox"
            name="Created Date | Time"
            checked={selectedNames?.includes("Created Date | Time")}
            onChange={() => handleCheckboxChange("Created Date | Time")}
            id="edit_label"
            label="Created Date | Time"
          />
          <Form.Check
            type="checkbox"
            name="Update Date | Time"
            checked={selectedNames?.includes("Update Date | Time")}
            onChange={() => handleCheckboxChange("Update Date | Time")}
            id="edit_label"
            label="Update Date | Time"
          />
          {studyDynamicColumn
            ? studyDynamicColumn.map(({ name }, i) => (
                <div key={i} className="d-flex align-items-center">
                  <Form.Check
                    type="checkbox"
                    name={name}
                    checked={selectedNames?.includes(name)}
                    onChange={() => handleCheckboxChange(name)}
                    label={name}
                  />
                </div>
              ))
            : ""}
          <div className="border-top text-end my-2 pt-2">
            <button
              onClick={() => handleSubmitTableProperty()}
              className="updateProfile"
            >
              <FaCheck /> Apply
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default STDMCommentTableProperties;
