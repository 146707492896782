import React, { useState } from "react";
import { Collapse } from "react-bootstrap";

function CtmsSidebar({ handleAddNewTab, checkActiveTab }) {
  const [openSites, setOpenSites] = useState(false);
  const ctmsPermissionData = JSON.parse(localStorage.getItem("ctmsPermissionData"));
  return (
    <>
      <div className="link__tab">
        <div
          onClick={() => setOpenSites(!openSites)}
          aria-expanded={openSites}
          className={"text-start h-auto tab_url w-100"}
          style={{ background: "#ccd6dd" }}
        >
          <div className="hstack justify-content-between">
            <div>
              <i className={"me-2 ps-0 fa-solid  fa-folder"}></i>
              <span>StudyID</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        {(ctmsPermissionData?.siteManagementDBP || ctmsPermissionData?.siteManagementDV || ctmsPermissionData?.siteManagementApp) &&
          <div
            className={`CP small link__tab ${checkActiveTab("siteList") && "selectedMenu"
              }`}
            onClick={() => {
              handleAddNewTab("Sites", "fa-solid fa-address-card", "siteList");
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building ps-2 me-2 small"></i>
              Site Management
            </div>
          </div>
        }

        {(ctmsPermissionData?.eLearningManagementDBP || ctmsPermissionData?.eLearningManagementDV || ctmsPermissionData?.eLearningManagementApp) &&
          <div
            className={`CP small link__tab ${checkActiveTab("protocolMetadata") && "selectedMenu"
              }`}
            onClick={() => {
              handleAddNewTab(
                "Protocol Metadata",
                "fa-solid fa-address-card",
                "protocolMetadata"
              );
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building ps-2 me-2 small"></i>
              Protocol Metadata
            </div>
          </div>
        }

        {(ctmsPermissionData?.eventManagementDBP || ctmsPermissionData?.eventManagementDV || ctmsPermissionData?.eventManagementApp) &&
          <div
            className={`CP small link__tab ${checkActiveTab("studyEvent") && "selectedMenu"
              }`}
            onClick={() => {
              handleAddNewTab(
                "Event Management",
                "fa-solid fa-address-card",
                "studyEvent"
              );
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building ps-2 me-2 small"></i>
              Event Management
            </div>
          </div>
        }
        {(ctmsPermissionData?.subjectManagementDBP || ctmsPermissionData?.subjectManagementDV || ctmsPermissionData?.subjectManagementApp) &&
          <div
            className={`CP small link__tab ${checkActiveTab("subjectMetaData") && "selectedMenu"
              }`}
            onClick={() => {
              handleAddNewTab(
                "Subject Management",
                "fa-solid fa-address-card",
                "subjectMetaData"
              );
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building ps-2 me-2 small"></i>
              Subject Management
            </div>
          </div>
        }
        {(ctmsPermissionData?.treatmentManagementDBP || ctmsPermissionData?.treatmentManagementDV || ctmsPermissionData?.treatmentManagementApp) &&
          <div
            className={`CP small link__tab ${checkActiveTab("treatmentDesign") && "selectedMenu"
              }`}
            onClick={() => {
              handleAddNewTab(
                "Treatment Management",
                "fa-solid fa-address-card",
                "treatmentDesign"
              );
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building ps-2 me-2 small"></i>
              Treatment Management
            </div>
          </div>
        }

        {(ctmsPermissionData?.eLearningManagementDBP || ctmsPermissionData?.eLearningManagementDV || ctmsPermissionData?.eLearningManagementApp) &&
          <div
            className={`CP small link__tab ${checkActiveTab("trainingManagement") && "selectedMenu"
              }`}
            onClick={() => {
              handleAddNewTab(
                "Training Management",
                "fa-solid fa-address-card",
                "trainingManagement"
              );
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building ps-2 me-2 small"></i>
              Training Management
            </div>
          </div>
        }

        {(ctmsPermissionData?.studyConfigurationManagementDBP || ctmsPermissionData?.studyConfigurationManagementDV || ctmsPermissionData?.studyConfigurationManagementApp) &&
          <div
            className={`CP small link__tab ${checkActiveTab("studyConfiguration") && "selectedMenu"
              }`}
            onClick={() => {
              handleAddNewTab(
                "Study Configuration",
                "fa-solid fa-address-card",
                "studyConfiguration"
              );
            }}
          >
            <div className="text-start h-auto tab_url w-100">
              <i className="fa-solid fa-building ps-2 me-2 small"></i>
              Study Configuration
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default CtmsSidebar;
