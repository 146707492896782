import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { FaShield } from "react-icons/fa6";

function RBQMSidebar({ handleAddNewTab, checkActiveTab }) {
  const [openSites, setOpenSites] = useState(false);
  return (
    <>
      <div className="link__tab">
        <div
          onClick={() => setOpenSites(!openSites)}
          aria-expanded={openSites}
          className={"text-start h-auto tab_url w-100"}
          style={{ background: "#ccd6dd" }}
        >
          <div className="hstack justify-content-between">
            <div>
              <i className={"me-2 ps-0 fa-solid fa-folder"}></i>
              <span>RBQM (Risk based query management)</span>
            </div>
          </div>
        </div>
      </div>

      <Collapse in={true}>
        <div>
          <span
            className={`CP ps-3 small py-1 ${
              checkActiveTab("riskCategoryDataId") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                `Risk Category`,
                "fa-solid fa-shield",
                "riskCategoryDataId"
              );
            }}
          >
            <FaShield className="ps-1 me-2" style={{ fontSize: 16 }} />
            Risk Category
          </span>
          <span
            className={`CP ps-3 small py-1 ${
              checkActiveTab("riskAreaDataId") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                `Risk Area`,
                "fa-solid fa-shield",
                "riskAreaDataId"
              );
            }}
          >
            <FaShield className="ps-1 me-2" style={{ fontSize: 16 }} />
            Risk Area
          </span>
          <span
            className={`CP ps-3 small py-1 ${
              checkActiveTab("riskManagementDataId") && "selectedMenu"
            }`}
            onClick={() => {
              handleAddNewTab(
                `Risk Management`,
                "fa-solid fa-shield",
                "riskManagementDataId"
              );
            }}
          >
            <FaShield className="ps-1 me-2" style={{ fontSize: 16 }} />
            Risk Management
          </span>
        </div>
      </Collapse>
    </>
  );
}

export default RBQMSidebar;
