import React from "react";

const SiteHeaderMenu = ({
  studyDynamicColumn,
  cleanForAddNewSiteData,
  setUpdatedType,
  tableProperty,
  filterData,
  studyDynamicRow,
  addTableAndRegularView,
  updatedType,
  setFilter,
  filter,
  getStudySiteRowData,
  viewTypeData,
}) => {
  const StudyMode = JSON.parse(localStorage.getItem("StudyMode"));
  return (
    <>
      <div
        className="hstack justify-content-between border-bottom px-2"
        style={{ padding: "2.4px" }}
      >
        <div className="hstack gap-2 toolbar">
          {StudyMode === "DBP MODE" &&
            (studyDynamicColumn?.length > 0 ? (
              <>
                {viewTypeData === "ctms" &&
                  (updatedType !== "addRow" ? (
                    <>
                      <i
                        onClick={cleanForAddNewSiteData}
                        title="Add Site Row"
                        className="fa-solid fa-plus-square CP"
                      />
                      <div className="vr"></div>
                    </>
                  ) : (
                    <>
                      <i
                        onClick={() => setUpdatedType("")}
                        title="Table Full Mode"
                        className="fa-solid fa-table-list"
                      ></i>
                      <div className="vr"></div>
                    </>
                  ))}
                {viewTypeData === "ctms" &&
                  (updatedType === "editRow" && (
                    <>
                      <i
                        onClick={() => setUpdatedType("")}
                        title="Table Full Mode"
                        className="fa-solid fa-table-list"
                      ></i>
                      <div className="vr"></div>
                    </>
                  ))}
                {viewTypeData === "ctms" &&
                  (updatedType !== "editColumn" ? (
                    <>
                      <i
                        onClick={() => setUpdatedType("editColumn")}
                        title="Edit Site Column"
                        className="fa-regular fa-pen-to-square CP"
                      />
                      <div className="vr"></div>
                    </>
                  ) : (
                    <>
                      <i
                        onClick={() => setUpdatedType("")}
                        title="Table Full Mode"
                        className="fa-solid fa-table-list"
                      ></i>
                      <div className="vr"></div>
                    </>
                  ))}
              </>
            ) : (
              viewTypeData === "ctms" &&
              <>
                <i
                  onClick={() => {
                    setUpdatedType("addColumn");
                  }}
                  title="Add Site Column"
                  className="fa-solid fa-plus-square CP"
                />
                <div className="vr"></div>
              </>
            ))}
          {studyDynamicColumn?.length > 0 && (
            <>
              <i
                onClick={tableProperty}
                title="Table Properties"
                className="fa-solid fa-list CP"
              />
              <div className="vr"></div>
            </>
          )}
          {studyDynamicRow?.length > 0 && (
            <>
              <i
                onClick={filterData}
                title="Filter"
                className="fa-solid fa-filter CP"
              />
              <div className="vr"></div>
            </>
          )}
          {filter && (
            <i
              onClick={() => {
                setFilter(false);
                getStudySiteRowData();
              }}
              title="Clear Filter"
              className="fa-solid fa-filter-circle-xmark CP red"
            />
          )}
        </div>
        {viewTypeData === "edc" && (
          <button
            className="m-0 CP bg-main   text-white hstack"
            onClick={(e) => addTableAndRegularView(e, "regular")}
            title="Switch to Grid View"
            style={{ fontSize: 13 }}
          >
            <i className="fa-solid fa-toggle-off me-2"></i>
            Grid View
          </button>
        )}
      </div>
    </>
  );
};

export default SiteHeaderMenu;
