import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { CiExport, CiViewTable } from "react-icons/ci";
import SplitPane from "react-split-pane";
import { BiCollapse, BiExpand } from "react-icons/bi";
import LegacyTableProperties from "./LegacyTableProperties";
import LegacyTableData from "./LegacyTableData";
import ImportLegacy from "./ImportLegacy";
import { TbLayoutSidebarRightExpand } from "react-icons/tb";
import { AiOutlinePlus } from "react-icons/ai";
import { CgChevronDown } from "react-icons/cg";
import PropertyTable from "./PropertyTable";
import DatasetTable from "./DatasetTable";
import { cdiscServices } from "../../../../Services/CDISC/cdiscServices";
import UseToggle from "../../../../Hooks/UseToggle";
import { useParams } from "react-router";
import TemplateColumnProperties from "./TemplateColumnProperties";
import LockUnlockHistory from "./LockUnlockHistory";
import ReviewHistory from "./ReviewHistory";
import Activity from "./Activity";
import StudyCRFDataEntryQueryListComponent from "./QueryManagement/StudyCRFDataEntryQueryListComponent";
import AssignedUser from "./AssignedUser";
import ExportTableDataByColumn from "./ExportTableDataByColumn";

const Legacy = ({ onMouseDown, tableRef }) => {
  const [mode, setMode] = useState("add");
  const [loader, setLoader] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isDataFormOpen, setIsDataFormOpen] = useState(false);
  const {
    toggle,
    setToggle,
    toggle2,
    setToggle2,
    toggle3,
    setToggle3,
    toggle4,
    setToggle4,
    toggle5,
    setToggle5,
  } = UseToggle();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [studyDataTemplate, setStudyDataTemplate] = useState([]);
  const [isOpenProperty, setIsOpenProperty] = useState(false);
  const [isHalfScreen, setIsHalfScreen] = useState(false);
  const [activeTabs, setActiveTabs] = useState("properties");
  const [crfData, setCRFData] = useState("");
  const [crfDataId, setCRFDataId] = useState("");
  const [crfProperties, setCRFProperties] = useState("");
  const [viewType, setViewType] = useState("name");
  const { studyId } = useParams();
  const [studyDataTemplateRow, setStudyDataTemplateRow] = useState([]);
  const [studyDataTemplateColumn, setStudyDataTemplateColumn] = useState([]);
  const [showTemplateColumn, setShowTemplateColumn] = useState([]);
  const [loading, setLoading] = useState(false);

  const getStudyDataTemplateEntryData = async (studyId, uniqueId) => {
    setLoading(true);
    let data = await cdiscServices.getStudyDataTemplateEntryData(
      studyId,
      uniqueId
    );
    setStudyDataTemplateRow(data?.rows || []);
    setStudyDataTemplateColumn(data?.columns || []);
    setShowTemplateColumn(data?.showColumn || []);
    setLoading(false);
  };

  const findStudyDetails = async (studyId) => {
    let data = await cdiscServices.findStudyDetails(studyId);
    setCRFData(data?.data?.crfData);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const toggleHalfScreen = () => {
    setIsHalfScreen(!isHalfScreen);
  };

  const getStudyDataTemplates = async () => {
    setLoader(true);
    let data = await cdiscServices.getStudyDataTemplates(studyId);
    setStudyDataTemplate(data?.data || []);
    setLoader(false);
  };

  const HeaderTools = [
    {
      title: isFormOpen ? "Hide Form" : "Import Legacy",
      icon: isFormOpen ? <TbLayoutSidebarRightExpand /> : <AiOutlinePlus />,
      onClick: () => setIsFormOpen(!isFormOpen),
    },
    {
      title: "View Table Properties",
      icon: <CiViewTable />,
      onClick: setToggle,
    },
  ];

  useEffect(() => {
    findStudyDetails(studyId);
    getStudyDataTemplates();
  }, [studyId]);

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div
        className="hstack justify-content-between w-100 border-bottom"
        style={{ padding: "4.6px 9px" }}
      >
        <div className="hstack gap-2">
          {HeaderTools?.map((button, index) => (
            <React.Fragment key={index}>
              <button
                title={button.title}
                className="tabButtons fontSize12 border-0"
                style={{ padding: "0px 5px 2px", borderRadius: 3 }}
                onClick={button.onClick}
              >
                {button.icon}
              </button>
              {index < 1 && <div className="vr"></div>}
            </React.Fragment>
          ))}
        </div>
        <button
          className="tabButtons fontSize12 border-0  "
          title={!isFullScreen ? "Full Mode" : "Original Mode"}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <BiCollapse /> : <BiExpand />}
        </button>
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 185px)",
          }}
          defaultSize={
            isOpenProperty && studyDataTemplate?.length ? "40%" : "100%"
          }
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={isFormOpen ? "20%" : "100%"}
          >
            {isFormOpen && (
              <ImportLegacy
                crfData={crfData}
                studyId={studyId}
                crfDataId={crfDataId}
                setCRFDataId={setCRFDataId}
                getStudyDataTemplates={getStudyDataTemplates}
              />
            )}
            <>
              {loader ? (
                <div className="hstack justify-content-center h-100 w-100">
                  <Spinner />
                </div>
              ) : studyDataTemplate?.length === 0 ? (
                <div className="hstack justify-content-center h-100 w-100">
                  CRF Data Not Found!
                </div>
              ) : (
                <LegacyTableData
                  studyDataTemplate={studyDataTemplate}
                  getStudyDataTemplates={getStudyDataTemplates}
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                  setCRFDataId={setCRFDataId}
                  setCRFProperties={setCRFProperties}
                  setIsFormOpen={setIsFormOpen}
                  setIsOpenProperty={setIsOpenProperty}
                  setToggle2={setToggle2}
                  setToggle3={setToggle3}
                  toggle3={toggle3}
                  setToggle4={setToggle4}
                  toggle4={toggle4}
                />
              )}
            </>
          </SplitPane>
          {isOpenProperty && studyDataTemplate?.length > 0 && (
            <div
              className={`${isHalfScreen ? "full-screen" : ""}`}
              style={{ height: "100%" }}
            >
              <div
                className="hstack justify-content-between border-bottom px-2 pb-0 bg-light"
                style={{ padding: 5 }}
              >
                <div className="hstack me-1 gap-1">
                  {[
                    "properties",
                    "dataset",
                    "lock/Unlock History",
                    "assigned User",
                    "review History",
                    "query Management",
                    "activity",
                  ].map((tab) => (
                    <button
                      key={tab}
                      className={`buttonForTabs px-2 ${
                        activeTabs === tab ? "activee" : ""
                      }`}
                      onClick={() => setActiveTabs(tab)}
                      title={tab.charAt(0).toUpperCase() + tab.slice(1)}
                      style={{ borderRadius: "3px 3px 0px 0px" }}
                    >
                      {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                  ))}
                </div>
                <div className="hstack gap-1">
                  <button
                    className="tabButtons fontSize12 border-0"
                    style={{ borderRadius: "3px 3px 0px 0px" }}
                    title={"Hide"}
                    onClick={() => setIsOpenProperty(false)}
                  >
                    <CgChevronDown />
                  </button>
                  <button
                    className="tabButtons fontSize12 border-0"
                    style={{ borderRadius: "3px 3px 0px 0px" }}
                    title={!isHalfScreen ? "Full Mode" : "Original Mode"}
                    onClick={toggleHalfScreen}
                  >
                    {isHalfScreen ? <BiCollapse /> : <BiExpand />}
                  </button>
                </div>
              </div>
              {activeTabs === "dataset" && (
                <div className="border-bottom py-1 px-2 hstack gap-2">
                  {crfProperties?.dataEntrystatus === "unLocked" && (
                    <>
                      <button
                        title={isDataFormOpen ? "Hide Form" : "Add Data"}
                        className="tabButtons fontSize12 border-0"
                        style={{ padding: "0px 5px 2px", borderRadius: 3 }}
                        onClick={() => setIsDataFormOpen(!isDataFormOpen)}
                      >
                        {isDataFormOpen ? (
                          <TbLayoutSidebarRightExpand />
                        ) : (
                          <AiOutlinePlus onClick={() => setMode("add")} />
                        )}
                      </button>
                      <div className="vr"></div>
                    </>
                  )}
                  <button
                    title="Export"
                    className="tabButtons fontSize12 border-0 "
                    style={{ padding: "0px 5px 2px", borderRadius: 3 }}
                  >
                    <CiExport onClick={() => setToggle5()} />
                  </button>
                  <div className="vr"></div>
                  <button
                    title={viewType === "label" ? "By Name" : "By Label"}
                    className="tabButtons border-0"
                    style={{
                      padding: "2px 10px",
                      fontSize: 10,
                      borderRadius: 3,
                    }}
                    onClick={() =>
                      setViewType(viewType === "name" ? "label" : "name")
                    }
                  >
                    {viewType === "label" ? "By Name" : "By Label"}
                  </button>
                  <div className="vr"></div>
                  <button
                    title="Template Properties"
                    className="tabButtons border-0"
                    style={{
                      padding: "2px 10px",
                      fontSize: 10,
                      borderRadius: 3,
                    }}
                    onClick={setToggle2}
                  >
                    Template Properties
                  </button>
                </div>
              )}
              <SplitPane
                split="horizontal"
                className="position-relative"
                defaultSize="100%"
              >
                {activeTabs === "properties" ? (
                  <PropertyTable crfProperties={crfProperties} />
                ) : activeTabs === "dataset" ? (
                  <DatasetTable
                    onMouseDown={onMouseDown}
                    tableRef={tableRef}
                    studyId={studyId}
                    isDataFormOpen={isDataFormOpen}
                    uniqueId={crfProperties?.uniqueId}
                    viewType={viewType}
                    dataEntrystatus={crfProperties?.dataEntrystatus}
                    setMode={setMode}
                    setViewType={setViewType}
                    mode={mode}
                    setIsDataFormOpen={setIsDataFormOpen}
                    loading={loading}
                    setStudyDataTemplateRow={setStudyDataTemplateRow}
                    studyDataTemplateRow={studyDataTemplateRow}
                    studyDataTemplateColumn={studyDataTemplateColumn}
                    getStudyDataTemplateEntryData={
                      getStudyDataTemplateEntryData
                    }
                    showTemplateColumn={showTemplateColumn}
                    setLoading={setLoading}
                  />
                ) : activeTabs === "lock/Unlock History" ? (
                  <LockUnlockHistory
                    onMouseDown={onMouseDown}
                    tableRef={tableRef}
                    crfProperties={crfProperties}
                  />
                ) : activeTabs === "assigned User" ? (
                  <AssignedUser
                    onMouseDown={onMouseDown}
                    tableRef={tableRef}
                    crfProperties={crfProperties}
                    getStudyDataTemplates={getStudyDataTemplates}
                  />
                ) : activeTabs === "review History" ? (
                  <ReviewHistory
                    onMouseDown={onMouseDown}
                    tableRef={tableRef}
                    crfProperties={crfProperties}
                  />
                ) : activeTabs === "query Management" ? (
                  <StudyCRFDataEntryQueryListComponent
                    onMouseDown={onMouseDown}
                    tableRef={tableRef}
                    getStudyDataTemplates={getStudyDataTemplates}
                    templateId={crfProperties?._id}
                  />
                ) : activeTabs === "activity" ? (
                  <Activity onMouseDown={onMouseDown} tableRef={tableRef} />
                ) : (
                  ""
                )}
              </SplitPane>
            </div>
          )}
        </SplitPane>
      </div>
      <LegacyTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
      <TemplateColumnProperties
        Show={!toggle2}
        Hide={setToggle2}
        Title={"Template Column Properties"}
        tableRef={tableRef}
        onMouseDown={onMouseDown}
        studyId={studyId}
        uniqueId={crfProperties?.uniqueId}
        dataEntry={getStudyDataTemplateEntryData}
      />
      <ExportTableDataByColumn
        Show={!toggle5}
        Hide={setToggle5}
        Title={"Export CRF Data By Column"}
        tableRef={tableRef}
        onMouseDown={onMouseDown}
        studyId={studyId}
        uniqueId={crfProperties?.uniqueId}
      />
    </div>
  );
};

export default Legacy;
