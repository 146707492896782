import React from "react";
import ShowHideColumn from "../ShowHideColumn/ShowHideColumn";
import ToolTip from "../../../Assets/ToolTip/ToolTip";
import ToolTipWrapper from "../../../Assets/ToolTip/ToolTipWrapper";
import { Dropdown } from "react-bootstrap";
import {
  AtoZIcon,
  BarsIcon,
  PlusIcon,
  DiagramSuccesorIcon,
  FileExportIcon,
  FilterIcon,
  ListIcon,
  MaximizeIcon,
  PenSquareIcon,
  PrintIcon,
  SquarePlusIcon,
  UploadIcon,
} from "../../../Assets/Icons/GlobalIcons";
import { HomeLine2Wrapper } from "../../Layouts/Layouts";
import { FaSignInAlt } from "react-icons/fa";
import { UseStudySubjectContext } from "../../../Contexts/UseStudySubjectContext";
import UseAddTabs from "../../../Hooks/UseAddTabs";

const HomeLine2 = ({
  viewType,
  siteSubject,
  addTableAndRegularView,
  StudySiteId,
  StudySubjectId,
  StudyVisitId,
  InitiateVisitId,
  StudyVisitForm,
  roleType,
  addFormMetadata2,
  handleCheck1,
  handleCheck2,
  handleCheck3,
  handleCheck4,
  handleCheck5,
  handleCheck6,
  handleCheck7,
  showColumn,
  appendPlusOnClick,
  CirclePlusOnClick,
  appendPlusOnClick2,
  CirclePlusOnClick2,
  StudyId,
  ExportCSV,
  ExportExcel,
  UpdateOnClick,
  CirclePlusToolTip,
  CirclePlusToolTip2,
  SquarePlusOnClick,
  SquarePlusToolTip,
  PenSquareToolTip,
  ColumnLength,
  DynamicActions,
  SingleAction,
  ExportAuditTrialCSV,
  ExportAuditTrialExcel,
  UploadIconToolTip,
  UploadOnClick,
  PushToClient,
  PushToProject,
  PushToSeprateClient,
  Visitcrf,
  Version,
  ShowHideColumns,
  Maximize,
  Print,
  Diagram,
  Bars,
  AtoZ,
  Filter,
  FilterTooltip,
  onClickFilter,
  assignOnclick,
  AssignToolTip,
  exportGlobalForm,
  addFormMetadata,
  access,
  NavigationBar,
  setColumnProperty,
  ColumnProperty,
}) => {
  const { Toggle, Menu, Item } = Dropdown;
  const permissionData = JSON.parse(localStorage.getItem("permissionData"));
  const studyPermissionData = JSON.parse(
    localStorage.getItem("studyPermissionData")
  );
  const previousRole = JSON.parse(localStorage.getItem("previousRole"));
  let role = JSON.parse(localStorage.getItem("role"));
  const {
    selectedStudySiteId,
    selectedStudySubjectId,
    selectedStudyVisitsId,
    selectedStudyVisitsInitiateId,
    selectedStudyVisitsForm,
    selectedStudyEventsId,
  } = UseStudySubjectContext();

  const {
    addStudySiteTab,
    addStudyRandomisationTab,
    addStudyVisitsTab,
    addStudyVisitsFormTab,
    addStudyScheduleTab,
  } = UseAddTabs();

  return (
    <>
      {NavigationBar && (
        <HomeLine2Wrapper
          siteManagementTab={addStudySiteTab}
          siteSubjectTab={addStudyRandomisationTab}
          studyVisitTab={addStudyVisitsTab}
          studyVisitFormTab={addStudyVisitsFormTab}
          studyEventManagement={addStudyScheduleTab}
          selectedStudySiteId={selectedStudySiteId}
          selectedStudySubjectId={selectedStudySubjectId}
          selectedStudyVisitsId={selectedStudyVisitsId}
          selectedStudyVisitsInitiateId={selectedStudyVisitsInitiateId}
          selectedStudyVisitsForm={selectedStudyVisitsForm}
          selectedStudyEventsId={selectedStudyEventsId}
          StudySiteId={StudySiteId}
          StudySubjectId={StudySubjectId}
          StudyVisitId={StudyVisitId}
          InitiateVisitId={InitiateVisitId}
          StudyVisitForm={StudyVisitForm}
        />
      )}
      <div
        className="hstack justify-content-between px-2"
      >
        <div>
          {StudyId && (
            <>
              {SingleAction && !permissionData?.createGlobalForm === true && (
                <ToolTipWrapper ToolTip={CirclePlusToolTip}>
                  <PlusIcon OnClick={appendPlusOnClick || CirclePlusOnClick} />
                </ToolTipWrapper>
              )}
              {permissionData?.createGlobalForm === true ||
                roleType === "adminRole" ||
                (permissionData?.addGlobleLevelForm && role === "user")
                ? addFormMetadata && (
                  <ToolTipWrapper ToolTip={CirclePlusToolTip}>
                    <PlusIcon
                      OnClick={appendPlusOnClick || CirclePlusOnClick}
                    />
                  </ToolTipWrapper>
                )
                : ""}
              {(studyPermissionData?.createDatabaseForm ||
                studyPermissionData?.sourceMonitoringVisitsLive ||
                (studyPermissionData?.sourceMonitoringVisitsQC &&
                  role === "studyId" &&
                  previousRole === "user") ||
                previousRole === "ctms") &&
                addFormMetadata2 && (
                  <ToolTipWrapper ToolTip={CirclePlusToolTip2}>
                    <PlusIcon
                      OnClick={appendPlusOnClick2 || CirclePlusOnClick2}
                    />
                  </ToolTipWrapper>
                )}

              {DynamicActions &&
                !permissionData?.createGlobalForm === true &&
                (access === "studySubjectVisit" ? (
                  ""
                ) : (
                  <>
                    {(studyPermissionData?.createDatabaseSites ||
                      studyPermissionData?.createDatabaseEvent ||
                      studyPermissionData?.createDatabaseStudyMetadata ||
                      studyPermissionData?.createDatabaseEventAndFormMatrix ||
                      studyPermissionData?.createProtocolMetadata ||
                      access === "studySubject" ||
                      studyPermissionData?.createDatabaseStudySubjects) &&
                      role === "studyId" &&
                      previousRole === "user" &&
                      ColumnLength?.length === 0 && CirclePlusToolTip ? (
                        <ToolTipWrapper ToolTip={CirclePlusToolTip}>
                          <PlusIcon OnClick={CirclePlusOnClick} />
                        </ToolTipWrapper>
                      ) : (
                        <div className="hstack gap-2">
                          {SquarePlusToolTip && (
                            <ToolTipWrapper ToolTip={SquarePlusToolTip}>
                              <SquarePlusIcon OnClick={SquarePlusOnClick} />
                            </ToolTipWrapper>
                          )}
                          {PenSquareToolTip && (
                            <ToolTipWrapper ToolTip={PenSquareToolTip}>
                              <PenSquareIcon OnClick={UpdateOnClick} />
                            </ToolTipWrapper>
                          )}
                          {ColumnProperty && (
                            <ToolTipWrapper ToolTip={ColumnProperty}>
                              <div onClick={setColumnProperty}>
                                <ListIcon />
                              </div>
                            </ToolTipWrapper>
                          )}
                          {Filter && (
                            <ToolTipWrapper ToolTip={FilterTooltip}>
                              <div onClick={onClickFilter}>
                                <FilterIcon />
                              </div>
                            </ToolTipWrapper>
                          )}
                        </div>
                      )}
                  </>
                ))}

              {Version && (
                <ToolTipWrapper ToolTip="Select Version">
                  <select
                    className="form-select cdashselect"
                    id="autoSizingSelect"
                  >
                    <option defaultValue="">1</option>
                    <option value="1">2</option>
                    <option value="2">3</option>
                    <option value="3">4</option>
                  </select>
                </ToolTipWrapper>
              )}

              {Maximize && (
                <ToolTipWrapper>
                  <MaximizeIcon />
                </ToolTipWrapper>
              )}
            </>
          )}

          {assignOnclick && (
            <ToolTipWrapper ToolTip={AssignToolTip}>
              <FaSignInAlt className="CP blue me-1" onClick={assignOnclick} />
            </ToolTipWrapper>
          )}

          {Print && (
            <ToolTipWrapper ToolTip="Print">
              <PrintIcon />
            </ToolTipWrapper>
          )}

          {Visitcrf && (
            <ToolTipWrapper ToolTip="Export">
              <span className="navSidebarDropdown ps-1">
                <Dropdown>
                  <Toggle variant="" className="p-0">
                    <FileExportIcon Class="mx-2" />
                  </Toggle>
                  <Menu>
                    <Item onClick={ExportCSV} className="small">
                      Export Without Audit Trial CSV
                    </Item>
                    <Item onClick={ExportExcel} className="small">
                      Export Without Audit Trial Excel
                    </Item>
                    <Item onClick={ExportAuditTrialCSV} className="small">
                      Export With Audit Trial CSV
                    </Item>
                    <Item onClick={ExportAuditTrialExcel} className="small">
                      Export With Audit Trial Excel
                    </Item>
                  </Menu>
                </Dropdown>
              </span>
            </ToolTipWrapper>
          )}

          {exportGlobalForm && roleType === "adminRole" && (
            <ToolTipWrapper ToolTip="Export">
              <span className="navSidebarDropdown ps-1">
                <Dropdown>
                  <Toggle variant="" className="p-0">
                    <FileExportIcon />
                  </Toggle>
                  <Menu>
                    <Item onClick={ExportCSV} className="small">
                      Export Without Audit Trial CSV
                    </Item>
                    <Item onClick={ExportExcel} className="small">
                      Export Without Audit Trial Excel
                    </Item>
                    <Item onClick={ExportAuditTrialCSV} className="small">
                      Export With Audit Trial CSV
                    </Item>
                    <Item onClick={ExportAuditTrialExcel} className="small">
                      Export With Audit Trial Excel
                    </Item>
                  </Menu>
                </Dropdown>
              </span>
            </ToolTipWrapper>
          )}

          {roleType === "adminRole"
            ? ""
            : UploadOnClick && (
              <ToolTipWrapper ToolTip={UploadIconToolTip}>
                <span className="navSidebarDropdown ps-1">
                  <Dropdown>
                    <Toggle variant="" className="p-0">
                      <UploadIcon />
                    </Toggle>

                    <Menu>
                      {PushToClient && (
                        <Item onClick={PushToClient} className="small">
                          Push To Study Form
                        </Item>
                      )}

                      {PushToProject && (
                        <Item onClick={PushToProject} className="small">
                          Push To Project Form
                        </Item>
                      )}

                      {PushToSeprateClient && (
                        <Item onClick={PushToSeprateClient} className="small">
                          Push To Client Form
                        </Item>
                      )}
                    </Menu>
                  </Dropdown>
                </span>
              </ToolTipWrapper>
            )}

          {Diagram && (
            <ToolTipWrapper>
              <DiagramSuccesorIcon />
            </ToolTipWrapper>
          )}

          {Bars && (
            <ToolTipWrapper>
              <BarsIcon />
            </ToolTipWrapper>
          )}

          {ShowHideColumns && (
            <span className="tooltips navSidebarDropdown ps-1">
              <ShowHideColumn
                ColumnLabelTitle1="1"
                ColumnLabelTitle2="2"
                ColumnLabelTitle3="3"
                ColumnLabelTitle4=""
                ColumnLabelTitle5=""
                ColumnLabelTitle6=""
                ColumnLabelTitle7=""
                handleCheck1={handleCheck1}
                handleCheck2={handleCheck2}
                handleCheck3={handleCheck3}
                handleCheck4={handleCheck4}
                handleCheck5={handleCheck5}
                handleCheck6={handleCheck6}
                handleCheck7={handleCheck7}
                showColumn={showColumn}
              />
              <ToolTip ToolTip="Show/Hide Column" />
            </span>
          )}

          {AtoZ && (
            <ToolTipWrapper>
              <AtoZIcon />
            </ToolTipWrapper>
          )}
        </div>

        <ToolTipWrapper ToolTip="">
          {siteSubject && (
            <button
              className="m-0 CP bg-main   text-white"
              onClick={(e) =>
                addTableAndRegularView(
                  e,
                  viewType === "regular" ? "table" : "regular"
                )
              }
              title="Switch to Grid View"
              style={{ fontSize: 13 }}
            >
              {viewType === "regular" ? "Table View" : "Grid View"}
            </button>
          )}
        </ToolTipWrapper>
      </div>
    </>
  );
};
export default HomeLine2;
