import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { ContextMenuTrigger } from "react-contextmenu";
import RightClick from "../../../../../LeftComponent/RightClick";
import { FaEdit, FaEye, FaQuestion } from "react-icons/fa";
import SplitPane from "react-split-pane";
import Pagination from "../../../../../ETMF/Pagination/Pagination";
import { MdDelete } from "react-icons/md";
import { PiSignatureBold } from "react-icons/pi";
import Moment from "react-moment";
import { SiGoogleforms } from "react-icons/si";
import { UseCrfViewContext } from "../../../../../../Contexts/UseCrfViewContext";
import { GrDocumentUpdate } from "react-icons/gr";

const StudySiteSubjectTableData = ({
  handleAddNewTab,
  studyDynamicColumn,
  hideColumn,
  studyDynamicRow,
  setIdUpdateRowTable,
  UpdateStudySubjectRowStatus,
  setIdReviewRowTable,
  handleSubmit3,
  getStudyRightSiteData,
  getStudySiteHistory,
  setIsSectionVisible,
  tableRef,
  onMouseDown,
  totalPage,
  totalData,
  startIndex,
  pageNumber,
  itemsPerPage,
  totalItems,
  setPageNumber,
  setItemsPerPage,
  seIdGenerateQuery,
  selectedStudySiteId,
  studySubjectState,
  siteIdData,
  viewTypeData,
}) => {
  let StudyMode = JSON.parse(localStorage.getItem("StudyMode"));

  const {
    setGetTicketsEndPoint,
    setQueryReplyEndpoint,
    setGetQueryListEndPoint,
    setQueryCommentEndpoint,
    setQueryGenerateEndpoint,
    setReviewHistoryEndpoint,
    setReviewFormFieldEndpoint,
    setFormFieldHistoryEndpoint,
    setUpdateTicketStatusEndPoint,
    setDataEntryLockFreezeFormByIdEndPoint,
  } = UseCrfViewContext();

  const studyPermissionData = JSON.parse(
    localStorage.getItem("studyPermissionData")
  );

  const viewStudySubjectIdOfVisit = (item) => {
    let idObject = {
      studySubjectIdOfVisitsState: item?.studyUniqueId,
      selectedStudySubjectId: item?.subjectIdLabel,
      selectedStudySiteId: selectedStudySiteId,
      siteIdData: siteIdData,
      subjectStudyUniqueId: item?.studyUniqueId,
      studySubjectState: studySubjectState,
    };
    let idSubjectObject = {
      studySubjectState: studySubjectState,
      selectedStudySiteId: selectedStudySiteId,
      siteIdData: siteIdData,
    };
    let subjectBreadcrumb = [
      {
        tabName: `Site: ${selectedStudySiteId}`,
        currentTabName: `Study Site`,
        uniqueId: `studySite`,
      },
      { tabName: `Subject List` },
    ];
    let breadcrumb = [
      {
        tabName: `Site: ${selectedStudySiteId}`,
        currentTabName: `Study Site`,
        uniqueId: `studySite`,
      },
      {
        tabName: `Subject: ${item?.subjectIdLabel}`,
        currentTabName: `${selectedStudySiteId} Subject`,
        uniqueId: `studySubject_${siteIdData}`,
        pageId: idSubjectObject,
        breadcrumb: subjectBreadcrumb,
      },
      { tabName: `Subject Event List` },
    ];
    handleAddNewTab(
      `${item?.subjectIdLabel} Subject Event`,
      "fa-solid fa-database",
      `subjectEventForm_${item.studyUniqueId}`,
      idObject,
      breadcrumb
    );
  };

  const addCrfViewTabsForSpecialForm = (item) => {
    let IdObject = {
      formUniqueId: item.formUniqueId,
      visitFormType: "regular",
      studyUniqueId: item.studyUniqueId,
      selectedStudySubjectId: item.subjectIdLabel,
      selectedStudySiteId: selectedStudySiteId,
      siteIdData: siteIdData,
      subjectStudyUniqueId: item.studyUniqueId,
      studySubjectState: studySubjectState,
    };
    let idSubjectObject = {
      studySubjectState: studySubjectState,
      selectedStudySiteId: selectedStudySiteId,
      siteIdData: siteIdData,
      subjectStudyUniqueId: item.studyUniqueId,
    };
    let subjectBreadcrumb = [
      {
        tabName: `Site: ${selectedStudySiteId}`,
        currentTabName: `Study Site`,
        uniqueId: `studySite`,
      },
      { tabName: `Subject List` },
    ];
    let breadcrumb = [
      {
        tabName: `Site: ${selectedStudySiteId}`,
        currentTabName: `Study Site`,
        uniqueId: `studySite`,
      },
      {
        tabName: `Subject: ${item?.subjectIdLabel}`,
        currentTabName: `${selectedStudySiteId} Subject`,
        uniqueId: `studySubject_${siteIdData}`,
        pageId: idSubjectObject,
        breadcrumb: subjectBreadcrumb,
      },
      { tabName: `Special Form List` },
    ];
    handleAddNewTab(
      `${item?.subjectIdLabel} Subject Special Form`,
      "fa-solid fa-database",
      `subjectSpecialForm_${item.studyUniqueId}`,
      IdObject,
      breadcrumb
    );
    setDataEntryLockFreezeFormByIdEndPoint(
      "getVisitsDataEntryLockFreezeFormByFormField"
    );
    setQueryGenerateEndpoint("studyFormQueryGenerator");
    setGetTicketsEndPoint("getStudyFormTickets");
    setGetQueryListEndPoint("getStudyFormQuery");
    setQueryReplyEndpoint("studyFormQueryReply");
    setQueryCommentEndpoint("studyFormQueryComment");
    setReviewHistoryEndpoint("getStudyLevelFormFieldReviewHistoryByID");
    setFormFieldHistoryEndpoint("getStudyLevelFormFieldHistoryByID");
    setReviewFormFieldEndpoint("reviewStudyFormField");
    setUpdateTicketStatusEndPoint("studyFormUpdateTicketStatus");
  };

  const [selectedRow, setSelectedRow] = useState(null);

  const isHighlightable = () =>
    studyPermissionData.createDatabaseSites ||
    studyPermissionData.viewMetaDataVerification ||
    studyPermissionData.createDatabaseViewSiteManagement ||
    studyPermissionData.createDatabaseVerifySiteManagement ||
    studyPermissionData.dataCollectionDBPMode ||
    studyPermissionData.dataCollectioninDVMode ||
    studyPermissionData?.siteIdForLive?.length > 0 ||
    studyPermissionData?.siteIdForQC?.length > 0 ||
    studyPermissionData?.siteIdForSDVLive?.length > 0 ||
    studyPermissionData?.siteIdForSDVQC?.length > 0;

  const getStatusColor = (status) => {
    switch (status) {
      case "open":
        return "red";
      case "cancel":
        return "grey";
      case "closed":
        return "green";
      case "inprogress":
        return "orange";
      default:
        return "black";
    }
  };

  return (
    <>
      <SplitPane split="vertical" minSize={20} defaultSize="100%">
        <div>
          <Table ref={tableRef} className="m-0 custom-table" hover striped>
            <thead className="position-sticky z-1">
              <tr>
                <th
                  onMouseDown={(e) => onMouseDown(e, 0)}
                  style={{ width: 45 }}
                >
                  S No.
                </th>
                {hideColumn?.includes("Subject Id") && (
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Subject Id</th>
                )}
                {hideColumn?.includes("Gender") && (
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Subject Gender</th>
                )}
                {studyDynamicColumn &&
                  studyDynamicColumn?.map(({ name, types }, i) => (
                    types === "dropdown" ? "" :
                    <th onMouseDown={(e) => onMouseDown(e, i + 2)}>{name}</th>
                  ))}
                {hideColumn?.includes("Status") && !viewTypeData && (
                  <th onMouseDown={(e) => onMouseDown(e, totalItems + 2)}>
                    Status
                  </th>
                )}
                {hideColumn?.includes("Query Status") && !viewTypeData && (
                  <th onMouseDown={(e) => onMouseDown(e, totalItems + 2)}>
                    Query Status
                  </th>
                )}
                {hideColumn?.includes("Query Date | Time") && !viewTypeData && (
                  <th onMouseDown={(e) => onMouseDown(e, totalItems + 2)}>
                    Query Date | Time
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {studyDynamicRow?.map((item, index) => (
                <React.Fragment key={index}>
                  <tr
                    style={{ cursor: "pointer" }}
                    className={
                      isHighlightable() &&
                      selectedRow === item.studyUniqueId &&
                      "highlighted"
                    }
                    onClick={(e) => {
                      if (isHighlightable()) {
                        getStudySiteHistory(item?.studyUniqueId);
                        getStudyRightSiteData(item);
                        setSelectedRow(item?.studyUniqueId);
                        setIsSectionVisible(true);
                      } else {
                        setIsSectionVisible(true);
                      }
                    }}
                  >
                    <td>
                      <ContextMenuTrigger
                        id={`studySubject_${index}`}
                        collect={() => ({ id: `studySubject_${index}` + 1 })}
                      >
                        {startIndex + index + 1}
                      </ContextMenuTrigger>
                      {!viewTypeData && (
                        <RightClick
                          ContextId={`studySubject_${index}`}
                          Menu2={
                            !(
                              StudyMode === "SDV Live Mode" ||
                              StudyMode === "SDV QC Mode"
                            )
                          }
                          MenuName2={"Edit Subject"}
                          icons2={<FaEdit />}
                          handleClick2={() => {
                            setIdUpdateRowTable(
                              item?._id,
                              item?.studyUniqueId,
                              item?.row,
                              item?.subjectIdLabel
                            );
                          }}
                          Menu3
                          MenuName3={"Subject Special Forms"}
                          icons3={<SiGoogleforms />}
                          handleClick3={(e) => {
                            addCrfViewTabsForSpecialForm(item);
                          }}
                          Menu4
                          MenuName4={"View Subject Events "}
                          icons4={<FaEye />}
                          handleClick4={() => {
                            viewStudySubjectIdOfVisit(item);
                          }}
                          Menu5={
                            !(
                              StudyMode === "SDV Live Mode" ||
                              StudyMode === "SDV QC Mode"
                            )
                          }
                          MenuName5={
                            item?.status
                              ? "Update in Not Complete Status"
                              : "Update in Complete Status"
                          }
                          icons5={<GrDocumentUpdate />}
                          handleClick5={(e) =>
                            UpdateStudySubjectRowStatus(e, item?._id)
                          }
                          Menu6={
                            StudyMode === "DV Mode" ||
                            StudyMode === "SDV Live Mode" ||
                            StudyMode === "SDV QC Mode"
                          }
                          MenuName6={"Siganture Verification"}
                          icons6={<PiSignatureBold />}
                          handleClick6={() =>
                            setIdReviewRowTable(item?.studyUniqueId)
                          }
                          Menu7={
                            StudyMode === "SDV Live Mode" ||
                            StudyMode === "SDV QC Mode"
                              ? false
                              : true
                          }
                          MenuName7={"Delete"}
                          icons7={
                            <MdDelete
                              className="delete"
                              style={{ width: 16, height: 16, marginLeft: -1 }}
                            />
                          }
                          handleClick7={(e) => handleSubmit3(e, item?._id)}
                          Menu8={
                            item?.queryStatus !== "open" &&
                            (StudyMode === "DV Mode" ||
                              StudyMode === "SDV Live Mode" ||
                              StudyMode === "SDV QC Mode")
                          }
                          MenuName8={"Generate Query"}
                          icons8={<FaQuestion />}
                          handleClick8={() =>
                            seIdGenerateQuery(item?.studyUniqueId)
                          }
                        />
                      )}
                    </td>
                    {hideColumn?.includes("Subject Id") && (
                      <td onClick={() => setIsSectionVisible(true)}>
                        <ContextMenuTrigger
                          id={`studySubject_${index}`}
                          collect={() => ({ id: `studySubject_${index}` + 1 })}
                        >
                          {item?.subjectIdLabel}
                        </ContextMenuTrigger>
                      </td>
                    )}
                    {hideColumn?.includes("Gender") && (
                      <td
                        className="text-capitalize"
                        onClick={() => setIsSectionVisible(true)}
                      >
                        <ContextMenuTrigger
                          id={`studySubject_${index}`}
                          collect={() => ({ id: `studySubject_${index}` + 1 })}
                        >
                          {item?.subjectGender}
                        </ContextMenuTrigger>
                      </td>
                    )}
                    {Object?.values(item?.row).map((data, index) => (
                      data?.types === "dropdown" ? "" :
                      <td key={index}>
                        <ContextMenuTrigger
                          id={`studySubject_${index}`}
                          collect={() => ({ id: `studySubject_${index}` + 1 })}
                        >
                          {data.valueData || "_"}
                        </ContextMenuTrigger>
                      </td>
                    ))}
                    {hideColumn?.includes("Status") && !viewTypeData && (
                      <td>
                        <ContextMenuTrigger
                          id={`studySubject_${index}`}
                          collect={() => ({ id: `studySubject_${index}` + 1 })}
                        >
                          {item?.status ? "Completed" : "Ongoing"}
                        </ContextMenuTrigger>
                      </td>
                    )}
                    {hideColumn?.includes("Query Status") && !viewTypeData && (
                      <td>
                        <ContextMenuTrigger
                          id={`studySubject_${index}`}
                          collect={() => ({ id: `studySubject_${index}` + 1 })}
                        >
                          {item?.queryStatus ? (
                            <div
                              className="text-capitalize"
                              style={{
                                color: getStatusColor(item?.queryStatus),
                              }}
                            >
                              {item?.queryStatus}
                            </div>
                          ) : (
                            "No Query"
                          )}
                        </ContextMenuTrigger>
                      </td>
                    )}
                    {hideColumn?.includes("Query Date | Time") &&
                      !viewTypeData && (
                        <td>
                          <ContextMenuTrigger
                            id={`studySubject_${index}`}
                            collect={() => ({
                              id: `studySubject_${index}` + 1,
                            })}
                          >
                            {item?.queryStatus === "open" ? (
                              <Moment format="DD MMM YYYY | HH:mm" withTitle>
                                {item?.queryDateTime}
                              </Moment>
                            ) : (
                              "N/A"
                            )}
                          </ContextMenuTrigger>
                        </td>
                      )}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
          <div className="position-sticky bottom-0">
            <Pagination
              totalPage={totalPage}
              pageNumber={pageNumber}
              itemsPerPage={itemsPerPage}
              totalItems={totalData?.length}
              setPageNumber={setPageNumber}
              setItemsPerPage={setItemsPerPage}
            />
          </div>
        </div>
      </SplitPane>
    </>
  );
};

export default StudySiteSubjectTableData;
